import { useContext } from "react"
import { Bar } from "react-chartjs-2"
import { FootprintContext } from "../../../controllers/footprint"
import ChartDataLabels from "chartjs-plugin-datalabels"
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
} from "chart.js"
import months from "../../../services/config/months"
import { t } from "i18next"
import { MainContext } from "../../../controllers/main"
import { TagsContext } from "../../../controllers/tags"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend,
  ChartDataLabels
)

const FootprintBarChart = () => {
  const { isMobile } = useContext(MainContext)
  const { userFootprint, footprintHistory } = useContext(FootprintContext)
  const { mainCategories } = useContext(TagsContext)

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Bar
        options={{
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 16,
              right: 16,
            },
          },
          plugins: {
            datalabels: {
              display: (value) => {
                if (value.datasetIndex === 4) {
                  return true
                } else {
                  return false
                }
              },
              font: {
                weight: 600,
                size: isMobile ? 10 : 12,
                family: "Roboto",
              },
              color: "#163B63",
              anchor: "end",
              align: "end",
              formatter: (value) => {
                return Math.round(value) + " Kg"
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                color: "#EFF0F3",
                drawTicks: false,
              },
              ticks: {
                color: "#163B63",
                padding: 6,
                font: {
                  weight: (value) => {
                    if (value.index === 3) {
                      return 600
                    } else {
                      return 300
                    }
                  },
                  family: "Roboto",
                },
              },
            },
            y: {
              stacked: false,
              max:
                userFootprint!.footprint < 1000 &&
                !footprintHistory.find((item) => item.footprint >= 1000)
                  ? 1000
                  : undefined,
              ticks: {
                stepSize:
                  userFootprint!.footprint < 1000 &&
                  !footprintHistory.find((item) => item.footprint >= 1000)
                    ? 250
                    : undefined,
                callback: (value) => {
                  if (value === 0) {
                    return "0 Kg"
                  } else if ((value as number) >= 1000) {
                    return `${
                      Math.round(((value as number) / 1000) * 10) / 10
                    } Ton`
                  } else {
                    return value
                  }
                },
                color: "#163B63",
                padding: 10,
                font: {
                  family: "Roboto",
                },
              },
              grid: {
                color: "#EFF0F3",
                drawTicks: false,
              },
            },
          },
          datasets: {
            bar: {
              barThickness: 25,
              minBarLength: 25,
            },
          },
        }}
        data={{
          labels: [
            t(months[footprintHistory[0].month - 1]).slice(0, 3),
            t(months[footprintHistory[1].month - 1]).slice(0, 3),
            t(months[footprintHistory[2].month - 1]).slice(0, 3),
            t(months[userFootprint!.month - 1]).slice(0, 3),
          ],
          datasets: [
            {
              data: [
                footprintHistory[0].sectors.food,
                footprintHistory[1].sectors.food,
                footprintHistory[2].sectors.food,
                userFootprint!.sectors.food,
              ],
              backgroundColor: [
                mainCategories.find(
                  (item) => item.id === "4550292f-08f5-4301-b1a5-8a08bf361744"
                )?.backTagColor,
              ],
              borderColor: [
                mainCategories.find(
                  (item) => item.id === "4550292f-08f5-4301-b1a5-8a08bf361744"
                )?.backTagColor,
              ],
              borderWidth: 0,
              borderRadius: {
                topLeft: 1000,
                topRight: 1000,
              },
            },
            {
              data: [
                footprintHistory[0].footprint -
                  (footprintHistory[0].sectors.energy +
                    footprintHistory[0].sectors.shopping +
                    footprintHistory[0].sectors.home),
                footprintHistory[1].footprint -
                  (footprintHistory[1].sectors.energy +
                    footprintHistory[1].sectors.shopping +
                    footprintHistory[1].sectors.home),
                footprintHistory[2].footprint -
                  (footprintHistory[2].sectors.energy +
                    footprintHistory[2].sectors.shopping +
                    footprintHistory[2].sectors.home),
                userFootprint!.footprint -
                  (userFootprint!.sectors.energy +
                    userFootprint!.sectors.shopping +
                    userFootprint!.sectors.home),
              ],
              backgroundColor: [
                mainCategories.find(
                  (item) => item.id === "84512f91-6760-4129-827a-6c34da547c77"
                )?.backTagColor,
              ],
              borderColor: [
                mainCategories.find(
                  (item) => item.id === "84512f91-6760-4129-827a-6c34da547c77"
                )?.backTagColor,
              ],
              borderWidth: 0,
              borderRadius: {
                topLeft: 1000,
                topRight: 1000,
              },
            },
            {
              data: [
                footprintHistory[0].footprint -
                  (footprintHistory[0].sectors.energy +
                    footprintHistory[0].sectors.shopping),
                footprintHistory[1].footprint -
                  (footprintHistory[1].sectors.energy +
                    footprintHistory[1].sectors.shopping),
                footprintHistory[2].footprint -
                  (footprintHistory[2].sectors.energy +
                    footprintHistory[2].sectors.shopping),
                userFootprint!.footprint -
                  (userFootprint!.sectors.energy +
                    userFootprint!.sectors.shopping),
              ],
              backgroundColor: [
                mainCategories.find(
                  (item) => item.id === "78fe8630-965e-4d4d-923f-ac500ed63031"
                )?.backTagColor,
              ],
              borderColor: [
                mainCategories.find(
                  (item) => item.id === "78fe8630-965e-4d4d-923f-ac500ed63031"
                )?.backTagColor,
              ],
              borderWidth: 0,
              borderRadius: {
                topLeft: 1000,
                topRight: 1000,
              },
            },
            {
              data: [
                footprintHistory[0].footprint -
                  footprintHistory[0].sectors.energy,
                footprintHistory[1].footprint -
                  footprintHistory[1].sectors.energy,
                footprintHistory[2].footprint -
                  footprintHistory[2].sectors.energy,
                userFootprint!.footprint - userFootprint!.sectors.energy,
              ],
              backgroundColor: [
                mainCategories.find(
                  (item) => item.id === "d498ed5b-265e-42bc-b488-309b70751d80"
                )?.backTagColor,
              ],
              borderColor: [
                mainCategories.find(
                  (item) => item.id === "d498ed5b-265e-42bc-b488-309b70751d80"
                )?.backTagColor,
              ],
              borderWidth: 0,
              borderRadius: {
                topLeft: 1000,
                topRight: 1000,
              },
            },
            {
              data: [
                footprintHistory[0].footprint,
                footprintHistory[1].footprint,
                footprintHistory[2].footprint,
                userFootprint!.footprint,
              ],
              backgroundColor: ["#A2C6F0"],
              borderColor: ["#A2C6F0"],
              borderWidth: 0,
              borderRadius: {
                topLeft: 1000,
                topRight: 1000,
              },
            },
          ],
        }}
      />
    </div>
  )
}

export default FootprintBarChart
