import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { mobileBreakpoint } from "../services/config/constants"
import { checkToken, get, post, put } from "../services/api/api"
import {
  parseAvatarsListData,
  parseUserData,
} from "../services/utils/parseFunctions"
import User from "../models/user"
import { useNavigate, useSearchParams } from "react-router-dom"
import Avatar from "../models/avatar"
import { cacheImages } from "../services/utils/utils"
import Mission from "../models/mission"

interface MainContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  tokenError: boolean
  signInError: boolean
  signUpError: boolean
  privacyError: boolean
  viewOnboarding: boolean
  viewTutorial: boolean
  setViewTutorial: Dispatch<SetStateAction<boolean>>
  viewAvatarSelection: boolean
  setViewAvatarSelection: Dispatch<SetStateAction<boolean>>
  setSignInError: Dispatch<SetStateAction<boolean>>
  setViewOnboarding: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
  windowWidth: number
  windowHeight: number
  lang: string
  user: User | null
  setUser: Dispatch<SetStateAction<User | null>>
  userError: boolean
  getUserInfo: (firstCall?: boolean) => Promise<boolean>
  setUserFirstAccess: () => Promise<boolean>
  signIn: () => void
  changeLang: (newLang: string) => Promise<boolean>
  visualizingErrorPage: boolean
  setVisualizingErrorPage: Dispatch<SetStateAction<boolean>>
  visualizingLoadingPage: boolean
  setVisualizingLoadingPage: Dispatch<SetStateAction<boolean>>
  currentTutorialPage: number
  setCurrentTutorialPage: Dispatch<SetStateAction<number>>
  avatars: Avatar[]
  changeAvatar: (newAvatar: string) => Promise<boolean>
  currentMission: Mission | null
  setCurrentMission: Dispatch<SetStateAction<Mission | null>>
  updatingMissions: boolean
  setUpdatingMissions: Dispatch<SetStateAction<boolean>>
  conditions: {
    beiren: boolean
    irenopen: boolean
    advertising: boolean
  }
  acceptTermsAndPrivacy: () => void
  urlBack: string
  isApp: boolean
}

const MainContext = createContext<MainContextInterface>({
  loading: true,
  setLoading: () => {},
  tokenError: false,
  signInError: false,
  signUpError: false,
  privacyError: false,
  viewOnboarding: true,
  viewTutorial: true,
  setViewTutorial: () => {},
  viewAvatarSelection: true,
  setViewAvatarSelection: () => {},
  setSignInError: () => {},
  setViewOnboarding: () => {},
  isMobile: false,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight - 50,
  lang: "it",
  user: null,
  setUser: () => {},
  userError: false,
  getUserInfo: async () => true,
  setUserFirstAccess: async () => true,
  signIn: () => {},
  changeLang: async () => false,
  visualizingErrorPage: false,
  setVisualizingErrorPage: () => {},
  visualizingLoadingPage: false,
  setVisualizingLoadingPage: () => {},
  currentTutorialPage: 0,
  setCurrentTutorialPage: () => {},
  avatars: [],
  changeAvatar: async () => true,
  currentMission: null,
  setCurrentMission: () => {},
  updatingMissions: false,
  setUpdatingMissions: () => {},
  conditions: { beiren: false, irenopen: false, advertising: false },
  acceptTermsAndPrivacy: () => {},
  urlBack: "",
  isApp: true,
})

const MainController = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  // loadings
  const [loading, setLoading] = useState<boolean>(true) // main loading
  const [updatingMissions, setUpdatingMissions] = useState<boolean>(true) // loading for missions update

  // states
  const [tokenError, setTokenError] = useState<boolean>(false) // signin error
  const [signInError, setSignInError] = useState<boolean>(false) // signin error
  const [signUpError, setSignUpError] = useState<boolean>(false) // signup error
  const [privacyError, setPrivacyError] = useState<boolean>(false) // privacy error
  const [viewOnboarding, setViewOnboarding] = useState<boolean>(true) // view or not sign up onboarding
  const [viewAvatarSelection, setViewAvatarSelection] = useState<boolean>(true) // view or not user avatar selection
  const [viewTutorial, setViewTutorial] = useState<boolean>(true) // view or not post sign up onboarding
  const [isMobile, setIsMobile] = useState<boolean>(false) // if screen is mobile size or not
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth) // window current width
  const [windowHeight, setWindowHeight] = useState<number>(
    window.innerHeight - 50
  ) // window current height
  const [lang, setLang] = useState<string>("it") // app language
  const [user, setUser] = useState<User | null>(null) // current user
  const [userError, setUserError] = useState<boolean>(false) // current user error
  const [visualizingErrorPage, setVisualizingErrorPage] =
    useState<boolean>(false) // if user is visualizing error page or not
  const [visualizingLoadingPage, setVisualizingLoadingPage] =
    useState<boolean>(false) // if user is visualizing loading page or not
  const [currentTutorialPage, setCurrentTutorialPage] = useState<number>(0) // current tutorial page
  const [avatars, setAvatars] = useState<Avatar[]>([]) // avatars list
  const [currentMission, setCurrentMission] = useState<Mission | null>(
    localStorage.getItem("currentMission")
      ? JSON.parse(localStorage.getItem("currentMission")!)
      : null
  ) // current mission
  const [conditions, setConditions] = useState<{
    beiren: boolean
    irenopen: boolean
    advertising: boolean
  }>({
    beiren: false,
    irenopen: false,
    advertising: false,
  }) // conditions
  const [urlBack, setUrlBack] = useState<string>("")
  const [isApp, setIsApp] = useState<boolean>(true)

  // change language
  const changeLang = async (newLang: string) => {
    try {
      await put("/web/user/update", { lang: newLang })

      return true
    } catch (e) {
      console.log(e)

      return false
    }
  }

  // get user
  const getUser = async () => {
    const { data } = await get("/web/user/get")

    return data
  }

  // get user wallet
  const getUserWallet = async () => {
    const { data } = await get("/web/mission/point/user")

    return data
  }

  // get all user info
  const getUserInfo = async (firstCall = false) => {
    setUserError(false)

    try {
      const result = await Promise.all([
        getUser(),
        getUserWallet(),
        getAvatars(),
        getConditions(),
      ])

      const userData = result[0]
      const userWallet = result[1]

      // parse data
      parseUserData(userData)
      if (userWallet.points) {
        userData.points = userWallet.points
      } else {
        userData.points = 0
      }

      console.log("user", userData)
      setUser(userData)

      // set app language based on user language
      if (!userData.lang || userData.lang !== "it") {
        await changeLang("it")
      }

      // don't show avatar selection if the user has already one set
      if (userData.profileImage) {
        setViewAvatarSelection(false)
      }
      if (firstCall) {
        if (!userData.firstAccess) {
          setViewTutorial(false)
        } else {
          navigate("/")
        }
      }

      return true
    } catch (e) {
      console.log("user error", e)
      setUserError(true)

      return false
    }
  }

  // get avatars list
  const getAvatars = async () => {
    try {
      const { data } = await get("/web/user/avatar/list")
      const dataToSet = parseAvatarsListData(data)
      console.log("avatars list", dataToSet)

      // cache avatars
      await cacheImages(dataToSet.map((item) => item.url))

      setAvatars(dataToSet)

      return true
    } catch (e) {
      console.log("avatars list error", e)
      return false
    }
  }

  // get conditions (beiren, irenopen and advertising)
  const getConditions = async () => {
    try {
      const { data } = await get("/web/iren/conditions")
      console.log("conditions", data)

      setConditions(data)
    } catch (e: any) {
      console.log("conditions error", e)
    }
  }

  // change user avatar
  const changeAvatar = async (newAvatar: string) => {
    try {
      await put("/web/user/profileimage", { profileImage: newAvatar })
      console.log(`avatar set ${newAvatar}`)

      // update user locally
      user!.profileImage = newAvatar
      setUser({ ...user! })

      return true
    } catch (e) {
      console.log("profile image change error", e)

      return false
    }
  }

  // set user first access to false
  const setUserFirstAccess = async () => {
    try {
      await put("/web/user/firstaccess")
      console.log("user firstAccess set to false")

      // set first access to false locally
      user!.firstAccess = false
      setUser({ ...user! })

      return true
    } catch (e) {
      console.log("firstaccess error", e)
      setUserError(true)

      return false
    }
  }

  // check if screen is mobile or not
  useEffect(() => {
    // first check
    if (window.innerWidth >= mobileBreakpoint) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }

    // event listener on resize
    window.addEventListener("resize", () => {
      if (window.innerWidth >= mobileBreakpoint) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }

      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight - 50)
    })
  }, [])

  // signin
  const signIn = async () => {
    try {
      // get key from query params
      const key = searchParams.get("key")

      let result
      try {
        result = await post(
          "/web/signIn",
          {},
          {
            token: key,
          },
          false
        )
      } catch (e: any) {
        if (e.response.status === 404) {
          result = await post(
            "/signup",
            {},
            {
              token: key,
            },
            false
          )
        } else {
          console.log("signin error", e)

          setSignInError(true)
          return
        }
      }

      // set tokens to local storage
      localStorage.setItem("accessToken", result.data.AccessToken)
      localStorage.setItem("refreshToken", result.data.RefreshToken)

      // get current user
      await getUserInfo(true)

      // check if privacy has already been accepted or not
      if (result.data.privacyAccepted) {
        setViewOnboarding(false)
      }

      setLoading(false)
    } catch (e) {
      console.log("signup error", e)

      setSignUpError(true)
    }
  }

  // accept terms and privacy
  const acceptTermsAndPrivacy = async () => {
    try {
      await post("/web/privacy", undefined, undefined, true)

      setViewOnboarding(false)
      setLoading(false)
    } catch (e: any) {
      console.log("privacy error", e)
      setPrivacyError(true)
    }
  }

  // check if an auth session is already present or not
  const checkSession = async () => {
    const key = searchParams.get("key")
    const accessToken = localStorage.getItem("accessToken")
    const refreshToken = localStorage.getItem("refreshToken")

    if (key) {
      signIn()
    } else if (accessToken && refreshToken) {
      await checkToken()
      await getUserInfo(true)
      setViewOnboarding(false)
      setLoading(false)
    } else {
      setLoading(false)
      setTokenError(true)
    }
  }

  // initial fetch
  useEffect(() => {
    const key = searchParams.get("key")
    const accessToken = localStorage.getItem("accessToken")
    const refreshToken = localStorage.getItem("refreshToken")

    // check key from params
    if ((!accessToken || !refreshToken) && !key) {
      setTokenError(true)
      return
    }

    // get url_back query param (if present, the user is not coming from the partner app)
    const urlBackParam = searchParams.get("url_back")

    if (urlBackParam) {
      setUrlBack(urlBackParam)
      setIsApp(false)
    }

    checkSession()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContext.Provider
      value={{
        loading,
        setLoading,
        tokenError,
        signInError,
        signUpError,
        privacyError,
        viewOnboarding,
        viewTutorial,
        setViewTutorial,
        viewAvatarSelection,
        setViewAvatarSelection,
        setSignInError,
        setViewOnboarding,
        isMobile,
        windowWidth,
        windowHeight,
        lang,
        user,
        setUser,
        userError,
        getUserInfo,
        setUserFirstAccess,
        signIn,
        changeLang,
        visualizingErrorPage,
        setVisualizingErrorPage,
        visualizingLoadingPage,
        setVisualizingLoadingPage,
        currentTutorialPage,
        setCurrentTutorialPage,
        avatars,
        changeAvatar,
        currentMission,
        setCurrentMission,
        updatingMissions,
        setUpdatingMissions,
        conditions,
        acceptTermsAndPrivacy,
        urlBack,
        isApp,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
export { MainController, MainContext }
