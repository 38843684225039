import { useContext } from "react"
import { colors } from "../../../services/config/colors"
import Button from "./Button"
import Text from "./Text"
import Title from "./Title"
import { Grow, Modal, Stack } from "@mui/material"
import { MainContext } from "../../../controllers/main"

const Alert = ({
  open,
  title,
  description,
  primaryActionLabel,
  primaryActionOnClick,
  secondaryActionLabel,
  secondaryActionOnClick,
  loading,
  isSecondaryActionPrimary = true,
  isTitleHtml = false,
  isDescriptionHtml = false,
}: {
  open: boolean
  title: string
  description: string
  primaryActionLabel: string
  primaryActionOnClick: () => void
  secondaryActionLabel?: string
  secondaryActionOnClick?: () => void
  loading?: boolean
  isSecondaryActionPrimary?: boolean
  isTitleHtml?: boolean
  isDescriptionHtml?: boolean
}) => {
  const { user } = useContext(MainContext)

  return (
    <Modal
      open={open}
      aria-labelledby={title}
      aria-describedby={description}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 50,
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <div
          style={{
            backgroundColor: colors.backgroundWhite,
            width: "calc(100% - 44px)",
            maxWidth: 375,
            height: "auto",
            borderRadius: 16,
            position: "relative",
          }}
        >
          {user && user.profileImage ? (
            <img
              src={user.profileImage}
              style={{
                width: 78,
                height: 78,
                borderRadius: "100%",
                position: "absolute",
                top: -39,
                left: 0,
                right: 0,
                marginInline: "auto",
              }}
              alt=""
            />
          ) : null}
          <Stack
            style={{
              maxHeight: "78vh",
              overflowY: "scroll",
              padding: 24,
              paddingTop: user && user.profileImage ? 72 : 24,
            }}
          >
            {isTitleHtml ? (
              <div
                id={title}
                style={{
                  fontSize: 26,
                  fontWeight: 700,
                  lineHeight: "30.4px",
                  color: colors.text,
                  textAlign: "center",
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            ) : (
              <Title
                fontSize={26}
                lineHeight="30.4px"
                paddingLeft={10}
                paddingRight={10}
                color={colors.text}
                textAlign="center"
                id={title}
              >
                {title}
              </Title>
            )}
            {isDescriptionHtml ? (
              <div
                id={description}
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "24px",
                  marginTop: 16,
                  color: colors.text,
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            ) : (
              <Text
                fontSize={18}
                fontWeight={400}
                lineHeight="24px"
                style={{ marginTop: 16 }}
                color={colors.text}
                textAlign="center"
                id={description}
              >
                {description}
              </Text>
            )}
            <Button
              width="100%"
              style={{ marginTop: 24, minHeight: 56 }}
              onClick={primaryActionOnClick}
              loading={loading}
              title={primaryActionLabel}
            >
              {primaryActionLabel}
            </Button>
            {secondaryActionLabel && (
              <Button
                outlined={!isSecondaryActionPrimary}
                width="100%"
                style={{ marginTop: 12, minHeight: 56 }}
                onClick={secondaryActionOnClick}
                disabled={loading}
                title={secondaryActionLabel}
              >
                {secondaryActionLabel}
              </Button>
            )}
          </Stack>
        </div>
      </Grow>
    </Modal>
  )
}

export default Alert
