import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { get } from "../services/api/api"
import FootprintForm from "../models/footprintForm"
import Footprint from "../models/footprint"

interface FootprintContextInterface {
  userFootprintLoading: boolean
  userFootprintError: boolean
  formsListLoading: boolean
  formsListError: boolean
  userFootprint: Footprint | null
  footprintHistory: Footprint[]
  formsList: FootprintForm[]
  getUserFootprint: (withLoading?: boolean) => Promise<boolean>
  getFormsList: (withLoading?: boolean) => Promise<boolean>
  gettingFootprint: boolean
  setGettingFootprint: Dispatch<SetStateAction<boolean>>
}

const FootprintContext = createContext<FootprintContextInterface>({
  userFootprintLoading: true,
  userFootprintError: false,
  formsListLoading: true,
  formsListError: false,
  userFootprint: null,
  footprintHistory: [],
  formsList: [],
  getUserFootprint: async () => true,
  getFormsList: async () => true,
  gettingFootprint: false,
  setGettingFootprint: () => {},
})

const FootprintController = ({ children }: { children: ReactNode }) => {
  // loadings
  const [userFootprintLoading, setUserFootprintLoading] =
    useState<boolean>(true)
  const [formsListLoading, setFormsListLoading] = useState<boolean>(true)

  // errors
  const [userFootprintError, setUserFootprintError] = useState<boolean>(false)
  const [formsListError, setFormsListError] = useState<boolean>(false)

  // states
  const [userFootprint, setUserFootprint] = useState<Footprint | null>(null)
  const [footprintHistory, setFootprintHistory] = useState<Footprint[]>([])
  const [formsList, setFormsList] = useState<FootprintForm[]>([])
  const [gettingFootprint, setGettingFootprint] = useState<boolean>(false)

  // get user footprint
  const getUserFootprint = async (withLoading = true) => {
    if (withLoading) {
      setUserFootprintLoading(true)
    }
    setUserFootprintError(false)

    try {
      const { data } = await get("/web/footprint/history")

      // parse data
      for (let i = 0; i < data.length; i++) {
        data[i].month = parseInt(data[i].month)
        data[i].year = parseInt(data[i].year)
        data[i].footprint =
          Math.round((data[i].footprint + Number.EPSILON) * 10) / 10
        data[i].sectors = {
          food: Math.round((data[i].footprintFood + Number.EPSILON) * 10) / 10,
          home: Math.round((data[i].footprintHome + Number.EPSILON) * 10) / 10,
          shopping:
            Math.round((data[i].footprintShopping + Number.EPSILON) * 10) / 10,
          transport:
            Math.round((data[i].footprintTransport + Number.EPSILON) * 10) / 10,
          energy:
            Math.round((data[i].footprintEnergy + Number.EPSILON) * 10) / 10,
        }
        delete data[i].footprintFood
        delete data[i].footprintHome
        delete data[i].footprintShopping
        delete data[i].footprintTransport
        delete data[i].footprintEnergy
      }
      data.sort(
        (
          a: { month: number; year: number },
          b: { month: number; year: number }
        ) => {
          if (new Date(a.year, a.month) < new Date(b.year, b.month)) {
            return -1
          }
          if (new Date(a.year, a.month) > new Date(b.year, b.month)) {
            return 1
          }
          return 0
        }
      )

      const currentMonth = new Date().getMonth() + 1
      const currentYear = new Date().getFullYear()

      console.log(
        "user footprint",
        data.filter(
          (item: any) =>
            parseInt(item.month) === currentMonth &&
            parseInt(item.year) === currentYear
        )[0]
      )
      console.log(
        "footprint history",
        data.filter(
          (item: any) =>
            parseInt(item.month) !== currentMonth ||
            parseInt(item.year) !== currentYear
        )
      )

      if (
        !data.filter(
          (item: any) =>
            parseInt(item.month) === currentMonth &&
            parseInt(item.year) === currentYear
        )[0].sectors.food &&
        !data.filter(
          (item: any) =>
            parseInt(item.month) === currentMonth &&
            parseInt(item.year) === currentYear
        )[0].sectors.home &&
        !data.filter(
          (item: any) =>
            parseInt(item.month) === currentMonth &&
            parseInt(item.year) === currentYear
        )[0].sectors.transport
      ) {
        setUserFootprint(null)
      } else {
        setUserFootprint(
          data.filter(
            (item: any) =>
              parseInt(item.month) === currentMonth &&
              parseInt(item.year) === currentYear
          )[0]
        )
        setFootprintHistory(
          data.filter(
            (item: any) =>
              parseInt(item.month) !== currentMonth ||
              parseInt(item.year) !== currentYear
          )
        )
      }

      setUserFootprintLoading(false)
      return true
    } catch (e) {
      console.log("user footprint error", e)
      setUserFootprintError(true)

      return false
    }
  }

  // get footprint forms list
  const getFormsList = async (withLoading = true) => {
    if (withLoading) {
      setFormsListLoading(true)
    }
    setFormsListError(false)

    try {
      const { data } = await get("/web/footprint/index")

      console.log("forms list", data)
      setFormsList(data)

      setFormsListLoading(false)

      return true
    } catch (e) {
      console.log("forms list error", e)
      setFormsListError(true)

      return false
    }
  }

  // initial fetch
  useEffect(() => {
    getUserFootprint()
    getFormsList()
  }, [])

  return (
    <FootprintContext.Provider
      value={{
        userFootprintLoading,
        userFootprintError,
        formsListLoading,
        formsListError,
        userFootprint,
        footprintHistory,
        formsList,
        getUserFootprint,
        getFormsList,
        gettingFootprint,
        setGettingFootprint,
      }}
    >
      {children}
    </FootprintContext.Provider>
  )
}
export { FootprintController, FootprintContext }
