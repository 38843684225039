import { ButtonBase, LinearProgress, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/common/Title"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import settingsIcon from "../../assets/icons/settings.svg"
import { useNavigate } from "react-router-dom"
import Text from "../global/common/Text"
import Appear from "../animations/Appear"
import footprintDoughnutImage from "../../assets/images/footprint-doughnut.svg"
import chevronRightIcon from "../../assets/icons/chevron-right.svg"
import infoIcon from "../../assets/icons/info-primary.svg"
import AppearFromSide from "../animations/AppearFromSide"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import PointsHistoryListItem from "./common/PointsHistoryListItem"
import { scrollWindowToTop } from "../../services/utils/utils"
import { FootprintContext } from "../../controllers/footprint"
import errorIcon from "../../assets/icons/error.svg"
import { MissionsContext } from "../../controllers/missions"
import { MainContext } from "../../controllers/main"
import { MissionSubType } from "../../services/config/enum"
import ServiceCard from "../services/common/ServiceCard"
import Button from "../global/common/Button"
import Alert from "../global/common/Alert"

const PerformanceMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, setCurrentMission, conditions, isApp, urlBack } =
    useContext(MainContext)
  const { formsList, userFootprint } = useContext(FootprintContext)
  const {
    missions,
    missionsHistory,
    missionsHistoryUpdating,
    missionsHistoryNextToken,
    getMissionsHistory,
    completedMissionsCount,
  } = useContext(MissionsContext)

  // be iren info alert
  const [beIrenAlertOpen, setBeIrenAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        paddingBottom: 22,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/", { state: { fromPerformance: true } })
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("performance")}
        </Title>
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/performance/settings")
          }}
          aria-label={t("settings")}
        >
          <img src={settingsIcon} style={{ width: 24, height: 24 }} alt="" />
        </ButtonBase>
      </Stack>
      {/* user info */}
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: "100%",
          height: 77,
          marginTop: 20,
          paddingInline: 16,
          gap: 12,
        }}
      >
        <Appear fade>
          <ButtonBase
            aria-label={t("edit_avatar")}
            style={{ borderRadius: "100%" }}
            onClick={() => {
              navigate("/performance/settings/avataredit")
            }}
          >
            <div
              style={{
                width: 77,
                height: 77,
                borderRadius: "100%",
                backgroundImage: `url(${
                  user!.profileImage
                }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                backgroundSize: "105%, 100%",
                backgroundPosition: "center",
              }}
            />
          </ButtonBase>
        </Appear>
        <Appear style={{ width: "72%" }} fade>
          <Stack style={{ gap: 10 }}>
            <div
              style={{
                width: "fit-content",
                height: 32,
                borderRadius: 4,
                paddingInline: 6,
                border: "1px solid " + colors.primary,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text fontSize={14} fontWeight={700} color={colors.primary}>
                {user?.points} {t("points")}
              </Text>
            </div>
            {conditions.beiren ? (
              <Text
                fontSize={14}
                lineHeight="16px"
                style={{ fontStyle: "italic" }}
              >
                Equivalenti a {user?.points}pt Be Iren
              </Text>
            ) : (
              <Stack direction="row" alignItems="center" gap={1}>
                <Button
                  title="Registrati a Be Iren"
                  width={185}
                  fontSize={12}
                  style={{ height: 32 }}
                  onClick={() => {
                    if (isApp) {
                      window.location.href =
                        window.location.href.split("?")[0] + "?url=beiren"
                    } else {
                      window.location.href =
                        urlBack + "/carbon-footprint-redirect?url=beiren"
                    }
                  }}
                >
                  Registrati a Be Iren
                </Button>
                <ButtonBase
                  aria-label="Perchè devo registrarmi a Be Iren?"
                  style={{
                    borderRadius: "100%",
                  }}
                  onClick={() => {
                    setBeIrenAlertOpen(true)
                  }}
                >
                  <img src={infoIcon} style={{ width: 20, height: 20 }} />
                </ButtonBase>
              </Stack>
            )}
          </Stack>
        </Appear>
      </Stack>
      {!userFootprint &&
      !missions.some(
        (mission) => mission.missionSubType === MissionSubType.TYPEFORM_BASE
      ) ? null : (
        <AppearFromSide delay={0.05} fade>
          <ButtonBase
            disabled={
              !userFootprint &&
              !missions.some(
                (mission) =>
                  mission.missionSubType === MissionSubType.TYPEFORM_BASE
              )
            }
            style={{
              width: "calc(100% - 32px)",
              marginLeft: 16,
              marginTop: 18,
              borderRadius: 10,
            }}
            onClick={() => {
              scrollWindowToTop()
              if (
                formsList.length &&
                formsList.some((item) => !item.isRefine) &&
                !userFootprint
              ) {
                const missionToSet = missions.find(
                  (mission) =>
                    mission.missionSubType === MissionSubType.TYPEFORM_BASE
                )!
                setCurrentMission(missionToSet)
                localStorage.setItem(
                  "currentMission",
                  JSON.stringify(missionToSet)
                )
                navigate("/performance/footprint/form")
              } else {
                navigate("/performance/footprint")
              }
            }}
            aria-label={t("your_carbon_footprint")}
          >
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 45,
                paddingInline: 12,
                borderRadius: 10,
                boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
                gap: 10,
                position: "relative",
              }}
            >
              <img
                src={footprintDoughnutImage}
                style={{ width: 33, height: 30 }}
                alt=""
              />
              <Text fontSize={14} fontWeight={700} lineHeight="20px">
                {t("your_carbon_footprint")}
              </Text>
              <img
                src={chevronRightIcon}
                style={{
                  width: 24,
                  height: 24,
                  position: "absolute",
                  right: 12,
                }}
                alt=""
              />
              {!userFootprint ? (
                <img
                  src={errorIcon}
                  style={{
                    width: 22,
                    height: 22,
                    position: "absolute",
                    right: 48,
                  }}
                  alt={t("footprint_empty_state")}
                />
              ) : null}
            </Stack>
          </ButtonBase>
        </AppearFromSide>
      )}
      {/* completed missions */}
      <AppearFromSide
        delay={0.1}
        fade
        style={{ width: "100%", marginTop: 25, paddingInline: 16 }}
      >
        <Stack
          style={{
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            style={{ width: "100%", height: 30, position: "relative" }}
          >
            <Title fontSize={18} lineHeight="24px" component="h2">
              {t("completed_missions")}
            </Title>
          </Stack>
          {!completedMissionsCount ? (
            <Text fontSize={14} fontWeight={400} style={{ marginTop: 18 }}>
              {t("points_history_empty_state")}.
            </Text>
          ) : (
            <Stack
              id="missions-history-container"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: 396.5,
                borderRadius: 10,
                boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
                marginTop: 18,
                gap: 18,
                paddingTop: 18,
                paddingBottom: 18,
                overflowY: "scroll",
                position: "relative",
              }}
              onScroll={() => {
                const container = document.getElementById(
                  "missions-history-container"
                )

                if (
                  container!.scrollTop + 224 + 400 >= container!.scrollHeight &&
                  missionsHistoryNextToken &&
                  !missionsHistoryUpdating
                ) {
                  getMissionsHistory(false)
                }
              }}
            >
              {missionsHistory.map((item, index) => (
                <Stack key={index} style={{ gap: 18 }}>
                  {index > 0 && (
                    <div
                      style={{
                        width: "100%",
                        height: 1.5,
                        backgroundColor: colors.backgroundLight,
                      }}
                    />
                  )}
                  <PointsHistoryListItem
                    title={item.title}
                    time={item.updatedAt!}
                    points={item.points}
                    type={item.missionType}
                  />
                </Stack>
              ))}
              {missionsHistoryUpdating && (
                <LinearProgress
                  style={{
                    width: "calc(100% - 32px)",
                    position: "fixed",
                    bottom: 0,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                />
              )}
            </Stack>
          )}
        </Stack>
      </AppearFromSide>
      {/* partner services */}
      <AppearFromSide
        delay={0.15}
        fade
        style={{ width: "100%", marginTop: 25, paddingInline: 16 }}
      >
        <Stack style={{ width: "100%" }}>
          <Title fontSize={18} lineHeight="24px" component="h2">
            Vuoi fare di più?
          </Title>
          <Stack
            style={{
              width: "100%",
              marginTop: 18,
              gap: 16,
            }}
          >
            <ServiceCard
              title="Prodotti Iren"
              description="Riduci le tue emissioni con i servizi Iren"
              buttonLabel="Scopri di più"
              buttonOnClick={() => {
                if (conditions.advertising) {
                  scrollWindowToTop()
                  navigate("/services")
                } else {
                  window.location.href =
                    window.location.href.split("?")[0] + "?esito=1"
                }
              }}
            />
          </Stack>
        </Stack>
      </AppearFromSide>
      {/* alerts */}
      <Alert
        open={beIrenAlertOpen}
        title="Perchè devo registrarmi a Be Iren?"
        description="Be Iren è il <strong>programma fedeltà</strong> dedicato a tutti i clienti domestici con un contratto fornitura di luce e/o gas attivo nel mercato libero.<br /><br /><strong>Be Iren ti permette di vincere ogni mese fantastici premi.</strong> Se sei iscritto al programma fedeltà Be Iren e completi le Missioni di AWorld, potrai accumulare fino a <strong>14 punti extra</strong> al mese da spendere all’interno del Catalogo e di accedere all’estrazione di ulteriori premi.<br /><br />Se non ti registri non potrai accedere al catalogo dei premi."
        isDescriptionHtml
        primaryActionLabel="Registrati"
        primaryActionOnClick={() => {
          if (isApp) {
            window.location.href =
              window.location.href.split("?")[0] + "?url=beiren"
          } else {
            window.location.href =
              urlBack + "/carbon-footprint-redirect?url=beiren"
          }
        }}
        secondaryActionLabel="Indietro"
        secondaryActionOnClick={() => {
          setBeIrenAlertOpen(false)
        }}
        isSecondaryActionPrimary={false}
      />
    </Stack>
  )
}

export default PerformanceMobile
