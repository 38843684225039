import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import FootprintMobile from "../../components/footprint/FootprintMobile"
import FootprintDesktop from "../../components/footprint/FootprintDesktop"
import { MissionsContext } from "../../controllers/missions"
import { FootprintContext } from "../../controllers/footprint"
import { registerPageEvent } from "../../services/utils/utils"

const Footprint = () => {
  const { isMobile } = useContext(MainContext)
  const { updateAll } = useContext(MissionsContext)
  const {
    gettingFootprint,
    setGettingFootprint,
    getUserFootprint,
    getFormsList,
  } = useContext(FootprintContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("footprint")
  }, [])

  // if user is coming from footprintForm page and has completed the form, get footprint and forms list
  useEffect(() => {
    if (gettingFootprint) {
      setTimeout(async () => {
        await Promise.all([getUserFootprint(false), getFormsList(false)])
        setGettingFootprint(false)
        updateAll()
      }, 2000)
    }
  }, [])

  // footprint tabs to switch between current footprint and last 4 months history
  const footprintTabs = useMemo(() => ["Ultimo mese", "Ultimi 4 mesi"], [])
  const [footprintSelectedTab, setFootprintSelectedTab] = useState<number>(0)

  return isMobile ? (
    <FootprintMobile
      footprintTabs={footprintTabs}
      footprintSelectedTab={footprintSelectedTab}
      setFootprintSelectedTab={setFootprintSelectedTab}
    />
  ) : (
    <FootprintDesktop
      footprintTabs={footprintTabs}
      footprintSelectedTab={footprintSelectedTab}
      setFootprintSelectedTab={setFootprintSelectedTab}
    />
  )
}

export default Footprint
