import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import AppearFromSide from "../animations/AppearFromSide"
import {
  defaultBoxShadowDarker,
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import FootprintCategoryCardDesktop from "./desktop/FootprintCategoryCardDesktop"
import FootprintCompensateCardDesktop from "./desktop/FootprintCompensateCardDesktop"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import FootprintDoughnutChart from "./common/FootprintDoughnutChart"
import { FootprintContext } from "../../controllers/footprint"
import { TagsContext } from "../../controllers/tags"
import { FootprintSector } from "../../services/config/enum"
import { useNavigate } from "react-router-dom"
import Button from "../global/common/Button"
import infoIconPrimary from "../../assets/icons/info-primary.svg"
import AlertInfo from "../global/common/AlertInfo"
import { MainContext } from "../../controllers/main"
import Appear from "../animations/Appear"
import chevronRightCircularIcon from "../../assets/icons/chevron-right-circular.svg"
import chevronLeftCircularIcon from "../../assets/icons/chevron-left-circular.svg"
import Tabs from "../global/common/Tabs"
import FootprintBarChart from "./common/FootprintBarChart"

const FootprintDesktop = ({
  footprintTabs,
  footprintSelectedTab,
  setFootprintSelectedTab,
}: {
  footprintTabs: string[]
  footprintSelectedTab: number
  setFootprintSelectedTab: Dispatch<SetStateAction<number>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(MainContext)
  const { userFootprint, formsList } = useContext(FootprintContext)
  const { mainCategories } = useContext(TagsContext)

  // how is footprint calculated info alert
  const [footprintCalculationsAlertOpen, setFootprintCalculationsAlertOpen] =
    useState<boolean>(false)

  // categories carousel
  const [currentCategoryPage, setCurrentCategoryPage] = useState<number>(0)

  useEffect(() => {
    const container = document.getElementById("categories-container")

    if (container) {
      container.scrollTo({
        left: currentCategoryPage === 0 ? 0 : 240 + desktopColumnsGap,
        behavior: "smooth",
      })
    }
  }, [currentCategoryPage])

  return (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("performance"),
            onClick: () => {
              navigate("/performance")
            },
          },
          { label: t("carbon_footprint") },
        ]}
        selectedItem={1}
        style={{ marginTop: 22 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack style={{ width: "100%", maxWidth: desktopMaxWidth }}>
          {/* header */}
          <AppearFromSide fade>
            <Title
              fontSize={36}
              lineHeight="46px"
              style={{ marginTop: 22 }}
              component="h1"
            >
              {t("carbon_footprint")}
            </Title>
          </AppearFromSide>
          <AppearFromSide fade delay={0.05}>
            <Text
              fontSize={28}
              lineHeight="46px"
              fontWeight={400}
              style={{ marginTop: 8 }}
            >
              {t("discover_your_emissions")}
            </Text>
          </AppearFromSide>
          <AppearFromSide fade delay={0.1}>
            <Title
              fontSize={26}
              lineHeight="46px"
              style={{ marginTop: 50 }}
              component="h2"
            >
              {t("your_annual_emissions")}
            </Title>
          </AppearFromSide>
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: 529,
              gap: desktopColumnsGap * 2,
              marginTop: 25,
            }}
          >
            {/* chart */}
            <AppearFromSide
              fade
              delay={0.15}
              style={{
                width: "45.5%",
                minWidth: "45.5%",
                height: "100%",
              }}
            >
              <Stack style={{ width: "100%", height: "100%" }}>
                <Tabs
                  tabs={footprintTabs}
                  selectedTab={footprintSelectedTab}
                  setSelectedTab={setFootprintSelectedTab}
                  disabled={!userFootprint}
                />
                <div
                  style={{
                    height: "100%",
                    maxHeight: "100%",
                    marginTop: 16,
                  }}
                >
                  {footprintSelectedTab === 0 ? (
                    <FootprintDoughnutChart />
                  ) : (
                    <FootprintBarChart />
                  )}
                </div>
              </Stack>
            </AppearFromSide>
            {/* missions */}
            <AppearFromSide
              fade
              delay={0.15}
              x={100}
              style={{ width: "100%", height: "100%" }}
            >
              <Stack
                gap={3}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                {/* help card */}
                <Stack
                  style={{
                    width: "100%",
                    height: 188,
                    borderRadius: 15,
                    border: "1px solid " + colors.primary,
                    padding: 14,
                  }}
                >
                  <Stack direction="row" alignItems="center" gap={1.5}>
                    <img
                      src={user?.profileImage}
                      style={{
                        width: 76,
                        height: 76,
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: 8,
                      }}
                      alt=""
                    />
                    <Title fontSize={20}>{t("do_you_need_help")}</Title>
                  </Stack>
                  <ButtonBase
                    disableRipple
                    style={{
                      width: "fit-content",
                      justifyContent: "flex-start",
                      marginTop: 18,
                    }}
                    aria-label={t("how_it_is_calculated")}
                    onClick={() => {
                      setFootprintCalculationsAlertOpen(true)
                    }}
                  >
                    <Stack direction="row" gap={1} style={{ height: 21 }}>
                      <img
                        src={infoIconPrimary}
                        style={{ width: 20, height: 20 }}
                        alt=""
                      />
                      <Title
                        fontSize={18}
                        lineHeight="21px"
                        color={colors.primary}
                      >
                        {t("how_it_is_calculated")}
                      </Title>
                    </Stack>
                  </ButtonBase>
                </Stack>
                {/* back to performance button */}
                <Button
                  title={t("back_to_performance")}
                  outlined
                  width="100%"
                  onClick={() => {
                    navigate("/performance")
                  }}
                >
                  {t("back_to_performance")}
                </Button>
                {/* compensate emissions card */}
                {userFootprint ? <FootprintCompensateCardDesktop /> : null}
              </Stack>
            </AppearFromSide>
          </Stack>
          <AppearFromSide fade delay={0.2} style={{ marginTop: 34 }}>
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <Title fontSize={26} lineHeight="35px" component="h2">
                {t("categories")}
              </Title>
              <Text
                fontSize={16}
                fontWeight={400}
                style={{ position: "absolute", right: 0 }}
              >
                {t("numbers_expressed_in_kg_co2e")}
              </Text>
            </Stack>
          </AppearFromSide>
          <AppearFromSide fade delay={0.25} style={{ marginTop: 6 }}>
            <Stack
              id="categories-container"
              direction="row"
              alignItems="center"
              style={{
                width: `calc(100% + ${desktopColumnsGap * 2}px)`,
                height: 220,
                gap: desktopColumnsGap,
                overflow: "hidden",
                paddingTop: 20,
                paddingBottom: 20,
                paddingInline: desktopColumnsGap,
                marginLeft: -desktopColumnsGap,
              }}
            >
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[0]}
                sector={FootprintSector.home}
                value={userFootprint ? userFootprint.sectors.home : undefined}
                givenAnswers={
                  formsList.some((item) => item.sector === "home") &&
                  !userFootprint
                    ? 0
                    : !formsList.some((item) => item.sector === "home") &&
                      userFootprint
                    ? 4
                    : 2
                }
                totalAnswers={4}
                hidden={currentCategoryPage === 1}
              />
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[1]}
                sector={FootprintSector.shopping}
                value={
                  userFootprint ? userFootprint.sectors.shopping : undefined
                }
                givenAnswers={
                  formsList.some((item) => item.sector === "shopping") &&
                  !userFootprint
                    ? 0
                    : !formsList.some((item) => item.sector === "shopping") &&
                      userFootprint
                    ? 6
                    : 2
                }
                totalAnswers={6}
              />
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[2]}
                sector={FootprintSector.transport}
                value={
                  userFootprint ? userFootprint.sectors.transport : undefined
                }
                givenAnswers={
                  formsList.some((item) => item.sector === "transport") &&
                  !userFootprint
                    ? 0
                    : !formsList.some((item) => item.sector === "transport") &&
                      userFootprint
                    ? 10
                    : 4
                }
                totalAnswers={10}
              />
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[3]}
                sector={FootprintSector.food}
                value={userFootprint ? userFootprint.sectors.food : undefined}
                givenAnswers={userFootprint ? 1 : 0}
                totalAnswers={1}
              />
              <FootprintCategoryCardDesktop
                mainCategory={{
                  backColor: "#99C7F4",
                  backTagColor: "#99C7F4",
                  foreColor: "#002E5B",
                  id: "energy",
                  name: "Energia",
                }}
                sector={FootprintSector.energy}
                value={userFootprint ? userFootprint.sectors.energy : undefined}
                givenAnswers={
                  formsList.some((item) => item.sector === "energy") &&
                  !userFootprint
                    ? 0
                    : !formsList.some((item) => item.sector === "energy") &&
                      userFootprint
                    ? 10
                    : 0
                }
                totalAnswers={10}
                hidden={currentCategoryPage === 0}
              />
            </Stack>
          </AppearFromSide>
          {/* categories carousel controls */}
          <Appear
            fade
            delay={0.25}
            style={{
              width: "100%",
              height: 51,
              marginTop: 30,
              marginBottom: 95,
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{
                width: "100%",
                height: "100%",
                gap: 35,
              }}
              role="group"
              aria-label={t("carousel_controls")}
            >
              <ButtonBase
                style={{
                  width: 51,
                  height: 51,
                  borderRadius: 30,
                  boxShadow: defaultBoxShadowDarker,
                }}
                aria-label={t("previous_slide")}
                aria-disabled={currentCategoryPage === 0 ? "true" : "false"}
                onClick={() => {
                  setCurrentCategoryPage((current) =>
                    current === 1 ? 0 : current
                  )
                }}
              >
                <img
                  src={chevronLeftCircularIcon}
                  style={{
                    height: 51,
                    transition: "200ms",
                  }}
                  alt=""
                />
              </ButtonBase>
              <Stack
                direction="row"
                alignItems="center"
                style={{ height: 17, gap: 9 }}
              >
                <ButtonBase
                  style={{
                    width: currentCategoryPage === 0 ? 22 : 15,
                    height: currentCategoryPage === 0 ? 17 : 11.6,
                    backgroundColor:
                      currentCategoryPage === 0
                        ? colors.primary
                        : colors.disabled,
                    borderRadius: 5,
                    transition: "200ms",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setCurrentCategoryPage(0)
                  }}
                  aria-label={t("go_to_slide_count_of_total", {
                    count: 1,
                    total: 2,
                  })}
                  aria-current={currentCategoryPage === 0 ? "true" : "false"}
                >
                  <Text
                    fontSize={16}
                    fontWeight={700}
                    color={colors.textWhite}
                    style={{
                      opacity: currentCategoryPage === 0 ? 1 : 0,
                      transition: "200ms",
                    }}
                  >
                    1
                  </Text>
                </ButtonBase>
                <ButtonBase
                  style={{
                    width: currentCategoryPage === 1 ? 22 : 15,
                    height: currentCategoryPage === 1 ? 17 : 11.6,
                    backgroundColor:
                      currentCategoryPage === 1
                        ? colors.primary
                        : colors.disabled,
                    borderRadius: 5,
                    transition: "200ms",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setCurrentCategoryPage(1)
                  }}
                  aria-label={t("go_to_slide_count_of_total", {
                    count: 2,
                    total: 2,
                  })}
                  aria-current={currentCategoryPage === 1 ? "true" : "false"}
                >
                  <Text
                    fontSize={16}
                    fontWeight={700}
                    color={colors.textWhite}
                    style={{
                      opacity: currentCategoryPage === 1 ? 1 : 0,
                      transition: "200ms",
                    }}
                  >
                    2
                  </Text>
                </ButtonBase>
              </Stack>
              <ButtonBase
                style={{
                  width: 51,
                  height: 51,
                  borderRadius: 30,
                  boxShadow: defaultBoxShadowDarker,
                }}
                aria-label={t("next_slide")}
                aria-disabled={currentCategoryPage === 1 ? "true" : "false"}
                onClick={() => {
                  setCurrentCategoryPage((current) =>
                    current === 0 ? 1 : current
                  )
                }}
              >
                <img
                  src={chevronRightCircularIcon}
                  style={{
                    height: 51,
                    transition: "200ms",
                  }}
                  alt=""
                />
              </ButtonBase>
            </Stack>
          </Appear>
        </Stack>
      </PaddingContainerDesktop>
      {/* how footprint is calculated info alert */}
      <AlertInfo
        open={footprintCalculationsAlertOpen}
        setOpen={setFootprintCalculationsAlertOpen}
        title={t("how_it_is_calculated")}
        description={t("footprint_description")}
        descriptionAlign="center"
      />
    </Stack>
  )
}

export default FootprintDesktop
