import { Grid, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import partnerLogo from "../../assets/images/partner-logo.png"
import partnerLogoAlt from "../../assets/images/partner-logo-alt.png"
import awLogo from "../../assets/images/aw-logo.svg"
import awLogoAlt from "../../assets/images/aw-logo-alt.svg"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import Button from "../global/common/Button"
import { useTranslation } from "react-i18next"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import { onboardingElementsMaxWidth } from "../../services/config/constants"
import AvatarButton from "../settings/common/AvatarButton"

const AvatarSelectionMobile = ({
  selectedAvatar,
  setSelectedAvatar,
  height,
  continueButtonWidth,
}: {
  selectedAvatar: string
  setSelectedAvatar: Dispatch<SetStateAction<string>>
  height?: number | string
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const {
    windowHeight,
    isMobile,
    avatars,
    changeAvatar,
    setViewAvatarSelection,
    conditions,
  } = useContext(MainContext)

  // loading for team selection
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.backgroundWhite,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      {/* content */}
      <Stack
        id="onboarding-container"
        direction="row"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: windowHeight,
          minHeight: 550,
          overflowY: "scroll",
          paddingTop: 26,
        }}
      >
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: isMobile ? 16 : 60,
          }}
          alignItems="center"
        >
          {/* header */}
          <FadeFromTop>
            <Stack direction="row" alignItems="center" style={{ gap: 24 }}>
              <img
                src={conditions.beiren ? partnerLogo : partnerLogoAlt}
                style={{ height: 42 }}
                alt={t("partner_logo_alt")}
              />
              <img
                src={conditions.beiren ? awLogo : awLogoAlt}
                style={{ height: conditions.beiren ? 21 : 38 }}
                alt={t("aworld_logo_alt")}
              />
            </Stack>
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 30 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
            >
              {t("your_avatar")}
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={500}
              component="h2"
            >
              {t("choose_your_avatar")}
            </Text>
          </FadeFromTop>
          <FadeFromTop
            delay={0.15}
            style={{
              width: "100%",
              maxWidth: onboardingElementsMaxWidth,
              marginTop: 60,
            }}
          >
            {isMobile ? (
              <Grid
                container
                columns={3}
                columnSpacing="16px"
                rowSpacing="16px"
                style={{ paddingInline: 16, marginBottom: 110 }}
              >
                {avatars.map((avatar, index) => (
                  <Grid key={index} item xs={1}>
                    <FadeFromTop delay={index * 0.025}>
                      <AvatarButton
                        avatar={avatar}
                        selected={selectedAvatar === avatar.url}
                        current={false}
                        disabled={loading}
                        onClick={() => {
                          setSelectedAvatar(avatar.url)
                        }}
                      />
                    </FadeFromTop>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Stack
                style={{
                  width: "100%",
                  height: "auto",
                  backgroundColor: colors.backgroundWhite,
                  borderRadius: 16,
                  boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                  paddingTop: 22,
                  paddingBottom: 22,
                  paddingInline: 28,
                }}
              >
                <Title fontSize={20} component="h2">
                  {t("avatar")}
                </Title>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  style={{
                    width: "100%",
                    height: "auto",
                    gap: 13.2,
                    marginTop: 24,
                  }}
                >
                  {avatars.slice(0, 5).map((avatar, index) => (
                    <AvatarButton
                      key={index}
                      avatar={avatar}
                      selected={selectedAvatar === avatar.url}
                      current={false}
                      disabled={loading}
                      onClick={() => {
                        setSelectedAvatar(avatar.url)
                      }}
                    />
                  ))}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  style={{
                    width: "100%",
                    height: "auto",
                    gap: 13.2,
                    marginTop: 24,
                  }}
                >
                  {avatars.slice(5).map((avatar, index) => (
                    <AvatarButton
                      key={index}
                      avatar={avatar}
                      selected={selectedAvatar === avatar.url}
                      current={false}
                      disabled={loading}
                      onClick={() => {
                        setSelectedAvatar(avatar.url)
                      }}
                    />
                  ))}
                </Stack>
              </Stack>
            )}
          </FadeFromTop>
        </Stack>
      </Stack>
      {/* continue button */}
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "100%",
          position: "fixed",
          bottom: 22,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          loading={loading}
          title={t("continue")}
          width={continueButtonWidth ?? "calc(100% - 32px)"}
          onClick={async () => {
            setLoading(true)

            await changeAvatar(selectedAvatar)

            setViewAvatarSelection(false)
          }}
        >
          {t("continue")}
        </Button>
      </FadeFromBottom>
    </Stack>
  )
}

export default AvatarSelectionMobile
