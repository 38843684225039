import ReactDOM from "react-dom/client"
import "./styles/index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { MainContext, MainController } from "./controllers/main"
import { BrowserRouter } from "react-router-dom"
import { Shadows, ThemeProvider, createTheme } from "@mui/material"
import { colors } from "./services/config/colors"
import { MotionConfig } from "framer-motion"
import "./services/i18n/i18n"
import CloseViewHeader from "./components/global/common/CloseViewHeader"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "light",
          primary: {
            main: colors.primary,
          },
          secondary: {
            main: colors.primary,
          },
        },
        shadows: Array(25).fill("none") as Shadows,
      })}
    >
      <MotionConfig reducedMotion="user">
        <MainController>
          <MainContext.Consumer>
            {(ctx) => (
              <div style={{ paddingTop: 50, height: ctx.windowHeight }}>
                <CloseViewHeader />
                <App />
              </div>
            )}
          </MainContext.Consumer>
        </MainController>
      </MotionConfig>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
