import { createContext, ReactNode } from "react"
import { deleteMethod } from "../services/api/api"

interface UsersContextInterface {
  deleteUser: () => Promise<boolean>
}

const UsersContext = createContext<UsersContextInterface>({
  deleteUser: async () => true,
})

const UsersController = ({ children }: { children: ReactNode }) => {
  // delete user
  const deleteUser = async () => {
    try {
      await deleteMethod("/web/user/delete")

      return true
    } catch (e) {
      console.log("user delete error", e)

      return false
    }
  }

  return (
    <UsersContext.Provider
      value={{
        deleteUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}
export { UsersController, UsersContext }
