import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import partnerLogo from "../../assets/images/partner-logo.png"
import partnerLogoAlt from "../../assets/images/partner-logo-alt.png"
import awLogo from "../../assets/images/aw-logo.svg"
import awLogoAlt from "../../assets/images/aw-logo-alt.svg"
import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import introImage from "../../assets/images/onboarding/intro.png"
import Button from "../global/common/Button"
import { useTranslation } from "react-i18next"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import { onboardingElementsMaxWidth } from "../../services/config/constants"
import infoIcon from "../../assets/icons/info-primary.svg"
import Alert from "../global/common/Alert"

const OnboardingMobile = ({
  height,
  continueButtonWidth,
}: {
  height?: number | string
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const {
    windowHeight,
    acceptTermsAndPrivacy,
    isMobile,
    conditions,
    isApp,
    urlBack,
  } = useContext(MainContext)

  // loading for signin
  const [loading, setLoading] = useState<boolean>(false)

  // be iren info alert
  const [beIrenAlertOpen, setBeIrenAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      className="hide-scrollbars"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.backgroundWhite,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      {/* content */}
      <Stack
        id="onboarding-container"
        direction="row"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: conditions.beiren ? 750 : 820,
          minHeight: conditions.beiren ? 750 : 820,
          overflow: "hidden",
          paddingTop: 26,
        }}
        tabIndex={0}
      >
        {/* first page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: isMobile ? 16 : 60,
          }}
          alignItems="center"
        >
          {/* header */}
          <FadeFromTop>
            <Stack direction="row" alignItems="center" gap={2}>
              <img
                src={conditions.beiren ? partnerLogo : partnerLogoAlt}
                style={{ height: 42 }}
                alt={t("partner_logo_alt")}
              />
              <img
                src={conditions.beiren ? awLogo : awLogoAlt}
                style={{ height: conditions.beiren ? 21 : 38 }}
                alt={t("aworld_logo_alt")}
              />
            </Stack>
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 30 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
            >
              {conditions.beiren
                ? "Be Iren ti dà il benvenuto in AWorld!"
                : "Ti diamo il benvenuto in AWorld!"}
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={500}
              component="h2"
            >
              {t("onboarding_subtitle")}
            </Text>
          </FadeFromTop>
          <FadeFromTop delay={0.15} style={{ marginTop: 42 }}>
            <img
              src={introImage}
              style={{ width: 203, height: 214 }}
              alt={t("onboarding_image_alt")}
            />
          </FadeFromTop>
          <FadeFromTop delay={0.2} style={{ marginTop: 42 }}>
            {conditions.beiren ? (
              <Text
                fontSize={isMobile ? 18 : 20}
                textAlign="center"
                style={{ maxWidth: onboardingElementsMaxWidth }}
              >
                <span style={{ fontWeight: 500 }}>
                  Agisci insieme a Be Iren e AWorld.
                </span>{" "}
                Completa le Missioni, ottieni Punti per riscattare premi e
                <span style={{ fontWeight: 500 }}>
                  scopri come puoi aiutare il Pianeta!
                </span>
              </Text>
            ) : (
              <Text
                fontSize={isMobile ? 18 : 20}
                textAlign="center"
                style={{ maxWidth: onboardingElementsMaxWidth }}
              >
                Completando le <span style={{ fontWeight: 500 }}>Missioni</span>
                , oltre ad aiutare il Pianeta, otterrai{" "}
                <span style={{ fontWeight: 500 }}>Punti Be Iren</span> per
                scegliere i <span style={{ fontWeight: 500 }}>premi</span> che
                preferisci dal Catalogo!
              </Text>
            )}
          </FadeFromTop>
        </Stack>
      </Stack>
      {/* continue button */}
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: 22,
          display: "flex",
          justifyContent: "center",
          transition: "bottom 200ms",
        }}
      >
        <Stack gap={1.5} style={{ width: "100%" }}>
          <Button
            loading={loading}
            title={t("continue")}
            width="100%"
            onClick={() => {
              setLoading(true)
              acceptTermsAndPrivacy()
            }}
          >
            {t("continue")}
          </Button>
          {!conditions.beiren && (
            <Button
              loading={loading}
              outlined
              title={t("continue")}
              width="100%"
              onClick={() => {
                if (isApp) {
                  window.location.href =
                    window.location.href.split("?")[0] + "?url=beiren"
                } else {
                  window.location.href =
                    urlBack + "/carbon-footprint-redirect?url=beiren"
                }
              }}
            >
              Registrati a Be Iren
            </Button>
          )}
          {!conditions.beiren && (
            <ButtonBase
              disableRipple
              style={{
                height: 22,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
                cursor: "pointer",
                gap: 8,
                marginTop: 6,
              }}
              aria-label="Perchè devo registrarmi a Be Iren?"
              onClick={() => {
                setBeIrenAlertOpen(true)
              }}
            >
              <img src={infoIcon} style={{ width: 18, height: 18 }} alt="" />
              <Text fontSize={14} fontWeight={700} color={colors.primary}>
                Perchè devo registrarmi a Be Iren?
              </Text>
            </ButtonBase>
          )}
        </Stack>
      </FadeFromBottom>
      <Alert
        open={beIrenAlertOpen}
        title="Perchè devo registrarmi a Be Iren?"
        description="Be Iren è il <strong>programma fedeltà</strong> dedicato a tutti i clienti domestici con un contratto fornitura di luce e/o gas attivo nel mercato libero.<br /><br /><strong>Be Iren ti permette di vincere ogni mese fantastici premi.</strong> Se sei iscritto al programma fedeltà Be Iren e completi le Missioni di AWorld, potrai accumulare fino a <strong>14 punti extra</strong> al mese da spendere all’interno del Catalogo e di accedere all’estrazione di ulteriori premi.<br /><br />Se non ti registri non potrai accedere al catalogo dei premi."
        isDescriptionHtml
        primaryActionLabel="Registrati"
        primaryActionOnClick={() => {
          if (isApp) {
            window.location.href =
              window.location.href.split("?")[0] + "?url=beiren"
          } else {
            window.location.href =
              urlBack + "/carbon-footprint-redirect?url=beiren"
          }
        }}
        secondaryActionLabel="Indietro"
        secondaryActionOnClick={() => {
          setBeIrenAlertOpen(false)
        }}
        isSecondaryActionPrimary={false}
      />
    </Stack>
  )
}

export default OnboardingMobile
