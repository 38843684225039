export const colors = {
  primary: "#0256A0", // iren color
  secondary: "#D02963", // same as missions => act
  tertiary: "#F49602",
  disabled: "#ECF4ED",
  background: "#ECF4ED", // iren background
  backgroundLight: "#F2F3F4", // Japanese Indigo + 95% white
  backgroundGray: "#EDE9E8", // 100% white
  backgroundWhite: "#FFFFFF", // 100% white
  stroke: "#7D8D96", // Japanese Indigo + 40% white
  text: "#0256A0", // iren color
  textSecondary: "#526773", // Japanese Indigo + 20% white
  textWhite: "#FFFFFF", // 100% white
  metrics: {
    co2: "#759357",
    water: "#5D82CA",
    energy: "#CE9256",
  },
  missions: {
    act: "#14856F",
    learn: "#D02963",
    measure: "#54428E",
  },
  success: "#008000",
  error: "#D02929",
  primaryGradient: "linear-gradient(90deg, #21599A 0%, #429247 100%)",
  secondaryGradient: "linear-gradient(90deg, #F7CA46 0%, #E07A2F 100%)",
}
