import { Stack } from "@mui/material"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { t } from "i18next"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import AppearFromSide from "../animations/AppearFromSide"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import Appear from "../animations/Appear"
import ServiceCardBig from "./common/ServiceCardBig"

const ServicesDesktop = ({
  servicesList,
}: {
  servicesList: {
    title: string
    description: string
    image: string
    onClick: () => void
  }[]
}) => {
  const { windowHeight } = useContext(MainContext)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: windowHeight - footerHeight,
      }}
    >
      <Stack style={{ width: "100%", backgroundColor: colors.backgroundWhite }}>
        {/* navbar background */}
        <div
          style={{
            width: "100%",
            height: navBarHeight,
            backgroundColor: colors.background,
          }}
        />
        {/* breadcrumb */}
        <BreadcrumbDesktop
          items={[{ label: t("services") }]}
          selectedItem={0}
          style={{ marginTop: 22 }}
        />
        {/* main container */}
        <PaddingContainerDesktop>
          <Stack
            style={{
              width: "100%",
              maxWidth: desktopMaxWidth,
            }}
          >
            {/* header */}
            <AppearFromSide fade>
              <Title
                fontSize={36}
                lineHeight="46px"
                style={{ marginTop: 22 }}
                component="h1"
              >
                {t("services")}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={28}
                lineHeight="46px"
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {t("lower_your_emissions_with_services")}
              </Text>
            </AppearFromSide>
            {/* services */}
            <Stack
              direction="row"
              style={{ gap: desktopColumnsGap, marginTop: 40 }}
            >
              {servicesList.slice(0, 2).map((service, index) => (
                <Appear
                  fade
                  key={service.title}
                  delay={0.05 + index * 0.05}
                  style={{ width: "50%" }}
                >
                  <ServiceCardBig service={service} />
                </Appear>
              ))}
            </Stack>
            <Stack
              direction="row"
              style={{
                gap: desktopColumnsGap,
                marginTop: 40,
                marginBottom: 40,
              }}
            >
              {servicesList.slice(2).map((service, index) => (
                <Appear
                  fade
                  key={service.title}
                  delay={0.15 + index * 0.05}
                  style={{ width: "50%" }}
                >
                  <ServiceCardBig service={service} />
                </Appear>
              ))}
            </Stack>
          </Stack>
        </PaddingContainerDesktop>
      </Stack>
    </Stack>
  )
}

export default ServicesDesktop
