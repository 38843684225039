import { ButtonBase, Stack } from "@mui/material"
import partnerLogo from "../../../assets/images/partner-logo.png"
import Text from "../../global/common/Text"
import Title from "../../global/common/Title"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"

const ServiceCardBig = ({
  service,
}: {
  service: {
    title: string
    description: string
    image: string
    onClick: () => void
  }
}) => {
  const { isMobile } = useContext(MainContext)

  return (
    <ButtonBase
      onClick={service.onClick}
      style={{ width: "100%", borderRadius: 10 }}
    >
      <Stack
        direction="row"
        style={{
          width: "100%",
          height: 167,
          minHeight: 167,
          borderRadius: 10,
          boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
          position: "relative",
          gap: 17,
          overflow: "hidden",
        }}
      >
        <img
          src={partnerLogo}
          style={{
            height: isMobile ? 20 : 22,
            position: "absolute",
            top: 13.5,
            right: 14,
          }}
        />
        <img
          src={service.image}
          style={{
            width: 131,
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        <Stack
          style={{
            width: "calc(100% - 148px)",
            height: "100%",
            boxSizing: "border-box",
            paddingRight: 20,
          }}
        >
          <Title
            fontSize={isMobile ? 11 : 12}
            fontWeight={800}
            lineHeight="14px"
            textAlign="left"
            style={{ marginTop: 21 }}
          >
            SERVIZIO
          </Title>
          <Text
            fontSize={isMobile ? 18 : 20}
            fontWeight={500}
            textAlign="left"
            style={{ marginTop: 8 }}
          >
            {service.title}
          </Text>
          <Text
            fontSize={isMobile ? 16 : 18}
            textAlign="left"
            fontWeight={400}
            style={{ marginTop: 8 }}
          >
            {service.description}
          </Text>
        </Stack>
      </Stack>
    </ButtonBase>
  )
}

export default ServiceCardBig
