import { colors } from "../../../services/config/colors"
import closeWhiteIcon from "../../../assets/icons/close-white.svg"
import { ButtonBase, Stack } from "@mui/material"
import {
  desktopMaxWidth,
  desktopPadding,
} from "../../../services/config/constants"
import { useTranslation } from "react-i18next"
import { useContext, useState } from "react"
import { MainContext } from "../../../controllers/main"
import Title from "./Title"
import Alert from "./Alert"

const CloseViewHeader = () => {
  const { t } = useTranslation()
  const {
    isMobile,
    visualizingErrorPage,
    visualizingLoadingPage,
    viewOnboarding,
    viewTutorial,
    urlBack,
    isApp,
  } = useContext(MainContext)

  // exit alert
  const [exitAlertOpen, setExitAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        height: 50,
        position: "fixed",
        top: 0,
        backgroundColor: colors.primary,
        zIndex: 1000000,
        paddingInline: isMobile
          ? 16
          : window.location.pathname.includes("/journey/") ||
            window.location.pathname.includes("/dailyepisode/") ||
            viewOnboarding ||
            viewTutorial ||
            visualizingErrorPage ||
            visualizingLoadingPage
          ? 30
          : desktopPadding,
        transition: "250ms",
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        style={{
          width: "100%",
          maxWidth: isMobile
            ? "100%"
            : window.location.pathname.includes("/journey/") ||
              window.location.pathname.includes("/dailyepisode/") ||
              viewOnboarding ||
              viewTutorial ||
              visualizingErrorPage ||
              visualizingLoadingPage
            ? "100%"
            : desktopMaxWidth,
          transition: "250ms",
          position: "relative",
        }}
      >
        <ButtonBase
          aria-label="Torna a IrenYou"
          style={{
            width: isMobile ? 38 : 42,
            height: isMobile ? 38 : 42,
            borderRadius: "100%",
          }}
          onClick={() => {
            setExitAlertOpen(true)
          }}
        >
          <img src={closeWhiteIcon} alt="" />
        </ButtonBase>
        <Title fontSize={isMobile ? 16 : 18} color={colors.textWhite}>
          Torna a IrenYou
        </Title>
      </Stack>
      {/* exit alert */}
      <Alert
        open={exitAlertOpen}
        title={t("exit_alert_title")}
        description={t("exit_alert_description")}
        primaryActionLabel={t("cancel")}
        primaryActionOnClick={() => {
          setExitAlertOpen(false)
        }}
        secondaryActionLabel={t("exit")}
        secondaryActionOnClick={() => {
          if (isApp) {
            window.location.href =
              window.location.href.split("?")[0] + "?esito=1"
          } else {
            window.location.href = urlBack + "/home"
          }
        }}
        isSecondaryActionPrimary={false}
      />
    </Stack>
  )
}

export default CloseViewHeader
