import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import Episode from "../models/episode"
import Journey from "../models/journey"
import QuizSlide from "../models/slides/quizSlide"
import { get, post } from "../services/api/api"
import {
  parseDailyEpisodeData,
  parseJourneyData,
} from "../services/utils/parseFunctions"
import { MainContext } from "./main"
import { MissionsContext } from "./missions"
import {
  cacheEpisodeImages,
  deepCopy,
  registerEvent,
} from "../services/utils/utils"

interface JourneysContextInterface {
  journey: Journey | null
  setJourney: Dispatch<SetStateAction<Journey | null>>
  journeys: Journey[]
  journeysLoading: boolean
  journeysError: boolean
  submitSurvey: (
    currentEpisode: Episode,
    answer: string,
    slide: QuizSlide,
    isDailyEpisode: boolean,
    isLastEpisode: boolean
  ) => void
  dailyEpisodeLoading: boolean
  dailyEpisodeError: boolean
  dailyEpisode: Episode | null
}

const JourneysContext = createContext<JourneysContextInterface>({
  journey: null,
  setJourney: () => {},
  journeys: [],
  journeysLoading: true,
  journeysError: false,
  submitSurvey: () => {},
  dailyEpisodeLoading: true,
  dailyEpisodeError: false,
  dailyEpisode: null,
})

const JourneysController = ({ children }: { children: ReactNode }) => {
  const { lang } = useContext(MainContext)
  const { updateAll } = useContext(MissionsContext)

  // loadings
  const [journeysLoading, setJourneysLoading] = useState<boolean>(true) // journeys loading
  const [dailyEpisodeLoading, setDailyEpisodeLoading] = useState<boolean>(true) // daily episode loading

  // errors
  const [journeysError, setJourneysError] = useState<boolean>(false) // journey error
  const [dailyEpisodeError, setDailyEpisodeError] = useState<boolean>(false) // daily episode error

  // states
  const [journey, setJourney] = useState<Journey | null>(null) // current journey
  const [journeys, setJourneys] = useState<Journey[]>([]) // all journeys
  const [dailyEpisode, setDailyEpisode] = useState<Episode | null>(null) // current daily episode

  // get all journeys
  const getJourneys = async () => {
    setJourneysLoading(true)
    setJourneysError(false)

    try {
      // get list of current journeys
      const date = new Date().toISOString().split("T")[0]
      const { data } = await get(`/web/plan?date=${date}&lang=${lang}`)

      // get every journey details
      let calls: any[] = []
      data.items.forEach((item: any) => {
        calls.push(getJourney(item.journeyId))
      })

      const result = await Promise.all(calls)

      console.log("journeys", result)

      setJourneys(result)
      setJourneysLoading(false)
    } catch (e: any) {
      console.log("journeys error", e)
      setJourneysLoading(false)
      setJourneysError(true)
    }
  }

  // get journey
  const getJourney = async (journeyId: string) => {
    try {
      const { data } = await get(
        `/web/plan/journey?journeyId=${journeyId}&lang=${lang}`
      )

      // get episodes
      let calls: any[] = []
      data.item.journey.episodes.forEach((episode: Episode) => {
        calls.push(getEpisode(episode.id))
      })
      const episodesResult = await Promise.all(calls)
      data.item.journey.episodes = episodesResult

      // parse data
      parseJourneyData(data.item.journey)

      return data.item.journey
    } catch (e: any) {
      console.log("journey error", e)
      setJourneysLoading(false)
      setJourneysError(true)
    }
  }

  // get episode
  const getEpisode = async (episodeId: string) => {
    try {
      const { data } = await get(
        `/web/plan/episode?episodeId=${episodeId}&lang=${lang}`
      )

      return data.item
    } catch (e: any) {
      console.log("episode error", e)
    }
  }

  // get daily episode
  const getDailyEpisode = async () => {
    setDailyEpisodeLoading(true)
    setDailyEpisodeError(false)

    try {
      const { data } = await get("/web/plan/dailyepisode")
      if (data.result.items[0]) {
        const result = await getEpisode(data.result.items[0].episodeId)

        // parse data
        parseDailyEpisodeData(result)

        // cache images
        cacheEpisodeImages(result)

        console.log("daily episode", result)
        setDailyEpisode(result)
      } else {
        console.log("daily episode", null)
      }

      setDailyEpisodeLoading(false)
    } catch (e: any) {
      console.log("daily episode error", e)
      setDailyEpisodeLoading(false)
      setDailyEpisodeError(true)
    }
  }

  // submit survey answer
  const submitSurvey = async (
    currentEpisode: Episode,
    answer: string,
    slide: QuizSlide,
    isDailyEpisode: boolean,
    isLastEpisode: boolean
  ) => {
    try {
      let body
      let episodeCompleted = false

      if (isDailyEpisode) {
        body = deepCopy({
          answer: answer,
          episodeId: currentEpisode.id,
          slideId: slide.id,
          slideType: slide.slideType,
        })

        // update locally daily episode
        const progression = {
          opt1: answer === "opt1" ? 1 : 0,
          opt2: answer === "opt2" ? 1 : 0,
          opt3: answer === "opt3" ? 1 : 0,
          opt4: answer === "opt4" ? 1 : 0,
          outcome: answer === slide.correctAnswer ? "success" : "fail",
          slideId: slide.id,
          slideType: slide.slideType,
          lang: lang,
        }

        dailyEpisode!.result.quiz.push(progression)
        episodeCompleted =
          dailyEpisode!.quiz.findIndex((item) => item.id === slide.id) ===
          dailyEpisode!.quiz.length - 1

        if (episodeCompleted) {
          registerEvent("daily_episode_completed", {
            id: dailyEpisode!.id,
          })
          dailyEpisode!.result.completed = true
          if (
            !dailyEpisode!.result.quiz.find(
              (quiz) => quiz.outcome !== "success"
            )
          ) {
            dailyEpisode!.result.success = true
          } else {
            dailyEpisode!.result.success = false
          }
        }

        setDailyEpisode({ ...dailyEpisode! })
      } else {
        body = deepCopy({
          answer: answer,
          episodeId: currentEpisode.id,
          journeyId: journey!.id,
          slideId: slide.id,
          slideType: slide.slideType,
        })

        // update locally current journey
        const progression = {
          opt1: answer === "opt1" ? 1 : 0,
          opt2: answer === "opt2" ? 1 : 0,
          opt3: answer === "opt3" ? 1 : 0,
          opt4: answer === "opt4" ? 1 : 0,
          outcome: answer === slide.correctAnswer ? "success" : "fail",
          slideId: slide.id,
          slideType: slide.slideType,
          lang: lang,
        }

        journey?.episodes
          .filter((episode) => episode.id === currentEpisode.id)[0]
          .result.quiz.push(progression)
        episodeCompleted =
          currentEpisode.quiz.findIndex((item) => item.id === slide.id) ===
          currentEpisode.quiz.length - 1

        if (episodeCompleted) {
          registerEvent("episode_completed", {
            id: journey?.episodes.filter(
              (episode) => episode.id === currentEpisode.id
            )[0].id,
          })
          journey!.episodes.filter(
            (episode) => episode.id === currentEpisode.id
          )[0].result.completed = true
          journey!.result.episodeCompleted++
          if (
            !journey?.episodes
              .filter((episode) => episode.id === currentEpisode.id)[0]
              .result.quiz.filter((quiz) => quiz.outcome !== "success").length
          ) {
            journey!.result.episodeSucceeded++
            journey!.episodes.filter(
              (episode) => episode.id === currentEpisode.id
            )[0].result.success = true
          } else {
            journey!.episodes.filter(
              (episode) => episode.id === currentEpisode.id
            )[0].result.success = false
          }
        }

        if (
          episodeCompleted &&
          !journey?.episodes.filter((episode) => !episode.result.completed)
            .length
        ) {
          registerEvent("journey_completed", {
            id: journey!.id,
          })
          journey!.result.completed = true
          if (
            journey?.result.episodeCompleted ===
            journey?.result.episodeSucceeded
          ) {
            journey!.result.success = true
          }
        }
        setJourney({ ...journey! })
      }

      // api call
      if (isDailyEpisode) {
        if (dailyEpisode!.result.completed) {
          await post(
            "/web/plan/episode/submit?dailyepisode=true&dailyepisodeend=true",
            body
          )
        } else {
          await post("/web/plan/episode/submit?dailyepisode=true", body)
        }
      } else if (isLastEpisode && episodeCompleted) {
        await post("/web/plan/episode/submit?journeyend=true", body)
      } else {
        await post("/web/plan/episode/submit", body)
      }

      // update
      updateAll()
    } catch (e: any) {
      console.log("submit survey error", e)
    }
  }

  // initial fetch
  useEffect(() => {
    getJourneys()
    getDailyEpisode()
  }, [])

  return (
    <JourneysContext.Provider
      value={{
        journey,
        setJourney,
        journeys,
        journeysLoading,
        journeysError,
        submitSurvey,
        dailyEpisodeLoading,
        dailyEpisodeError,
        dailyEpisode,
      }}
    >
      {children}
    </JourneysContext.Provider>
  )
}
export { JourneysController, JourneysContext }
