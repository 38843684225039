import { useContext, useMemo } from "react"
import { MainContext } from "../../controllers/main"
import ServicesMobile from "../../components/services/ServicesMobile"
import ServicesDesktop from "../../components/services/ServicesDesktop"
import irenPlusImage from "../../assets/images/partner/iren-plus.png"
import irenGoImage from "../../assets/images/partner/iren-go.png"
import beIrenImage from "../../assets/images/partner/be-iren.jpg"
import irenOpenImage from "../../assets/images/iren-open-service-background.jpg"

const Services = () => {
  const { isMobile, isApp, urlBack } = useContext(MainContext)

  // services to show
  const servicesList = useMemo(
    () => [
      {
        title: "IrenPlus",
        description:
          "Scopri i prodotti che ti aiutano a consumare in modo più efficiente",
        image: irenPlusImage,
        onClick: () => {
          window.location.href =
            "https://www.irenlucegas.it/prodotti/fotovoltaico-casa/"
        },
      },
      {
        title: "IrenGo",
        description:
          "Riduci le tue emissioni con la mobilità elettrica di IrenGo",
        image: irenGoImage,
        onClick: () => {
          window.location.href =
            "https://www.irenlucegas.it/prodotti/mobilita-elettrica/"
        },
      },
      {
        title: "IrenOpen",
        description:
          "Tieni sempre aggiornata la tua Carbon Footprint con l'impatto degli acquisti",
        image: irenOpenImage,
        onClick: () => {
          if (isApp) {
            window.location.href =
              window.location.href.split("?")[0] + "?url=irenopen"
          } else {
            window.location.href =
              urlBack + "/carbon-footprint-redirect?url=irenopen"
          }
        },
      },
      {
        title: "Be Iren",
        description: "Mettiti in gioco per ridurre le tue emissioni",
        image: beIrenImage,
        onClick: () => {
          if (isApp) {
            window.location.href =
              window.location.href.split("?")[0] + "?url=beiren"
          } else {
            window.location.href =
              urlBack + "/carbon-footprint-redirect?url=beiren"
          }
        },
      },
    ],
    [isApp, urlBack]
  )

  return isMobile ? (
    <ServicesMobile servicesList={servicesList} />
  ) : (
    <ServicesDesktop servicesList={servicesList} />
  )
}

export default Services
