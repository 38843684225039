import { createContext, ReactNode, useEffect, useState } from "react"
import { get } from "../services/api/api"
import Challenge from "../models/challenge"

interface ChallengeContextInterface {
  challengeLoading: boolean
  challengeError: boolean
  challenge: Challenge | null
  getChallenge: (withLoading?: boolean) => void
  savingsLoading: boolean
  savingsError: boolean
  challengeSavings: {
    Co2: number
    Water: number
    Energy: number
  } | null
  getSavings: (withLoading?: boolean) => void
}

const ChallengeContext = createContext<ChallengeContextInterface>({
  challengeLoading: true,
  challengeError: false,
  challenge: null,
  getChallenge: () => {},
  savingsLoading: true,
  savingsError: false,
  challengeSavings: null,
  getSavings: () => {},
})

const ChallengeController = ({ children }: { children: ReactNode }) => {
  // loadings
  const [challengeLoading, setChallengeLoading] = useState<boolean>(true)
  const [savingsLoading, setSavingsLoading] = useState<boolean>(true)

  // errors
  const [challengeError, setChallengeError] = useState<boolean>(false)
  const [savingsError, setSavingsError] = useState<boolean>(false)

  // states
  const [challenge, setChallenge] = useState<Challenge | null>(null) // current challenge
  const [challengeSavings, setChallengeSavings] = useState<{
    Co2: number
    Water: number
    Energy: number
  } | null>(null) // current challenge savings

  // get current challenge
  const getChallenge = async (withLoading = true) => {
    if (withLoading) {
      setChallengeLoading(true)
    }
    setChallengeError(false)

    try {
      const { data } = await get("/web/challenge/get")

      if (data) {
        console.log("challenge", data)
        setChallenge(data)
      }

      setChallengeLoading(false)
    } catch (e) {
      console.log("challenge error", e)
      setChallengeLoading(false)
      setChallengeError(true)
    }
  }

  // get challenge savings
  const getSavings = async (withLoading = true) => {
    if (withLoading) {
      setSavingsLoading(true)
    }
    setSavingsError(false)

    try {
      const { data } = await get("/web/community/savings")

      console.log("challenge savings", data.savings)
      setChallengeSavings(data.savings)

      setSavingsLoading(false)
    } catch (e) {
      console.log("challenge savings error", e)
      setSavingsLoading(false)
      setSavingsError(true)
    }
  }

  // initial fetch
  useEffect(() => {
    // getChallenge()
    setChallengeLoading(false)
    getSavings()
  }, [])

  return (
    <ChallengeContext.Provider
      value={{
        challengeLoading,
        challengeError,
        challenge,
        getChallenge,
        savingsLoading,
        savingsError,
        challengeSavings,
        getSavings,
      }}
    >
      {children}
    </ChallengeContext.Provider>
  )
}
export { ChallengeController, ChallengeContext }
