import { motion } from "framer-motion"
import { colors } from "../../../services/config/colors"
import { ButtonBase, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import closeWhiteIcon from "../../../assets/icons/close-white.svg"
import MenuButtonMobile from "./MenuButtonMobile"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Title from "../../global/common/Title"
import { MainContext } from "../../../controllers/main"

const MenuMobile = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setViewTutorial, conditions, urlBack } = useContext(MainContext)

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      variants={{
        open: { x: 0 },
        closed: { x: "-100%" },
      }}
      aria-hidden={open ? "false" : "true"}
      role="dialog"
      transition={{ type: "spring", damping: 20, stiffness: 200 }}
      initial={false}
      style={{
        width: "calc(100% + 40px)",
        height: "100%",
        backgroundColor: colors.backgroundWhite,
        position: "fixed",
        left: -40,
        zIndex: 15,
        overflowY: "scroll",
      }}
    >
      <ButtonBase
        disableRipple
        style={{
          position: "absolute",
          top: 24,
          right: 16,
          zIndex: 1,
        }}
        onClick={() => {
          setOpen(false)
        }}
        aria-label={t("close")}
        aria-hidden={open ? "false" : "true"}
        tabIndex={open ? 0 : -1}
      >
        <Stack direction="row" gap={1.5} alignItems="center">
          <Title fontSize={16} color={colors.primary}>
            {t("menu")}
          </Title>
          <div
            className="center"
            style={{
              width: 36,
              height: 36,
              borderRadius: "100%",
              background: colors.primaryGradient,
            }}
          >
            <img src={closeWhiteIcon} style={{ width: 10 }} alt="" />
          </div>
        </Stack>
      </ButtonBase>
      <Stack
        style={{
          width: "calc(100% - 72px)",
          marginLeft: 56,
          marginTop: 100,
          position: "relative",
          zIndex: 1,
        }}
      >
        <MenuButtonMobile
          title={t("home")}
          onClick={() => {
            setOpen(false)
          }}
          hidden={open ? "false" : "true"}
        />
        <MenuButtonMobile
          title={t("performance")}
          onClick={() => {
            setOpen(false)
            setTimeout(() => {
              navigate("/performance")
            }, 200)
          }}
          hidden={open ? "false" : "true"}
        />
        <MenuButtonMobile
          title={t("tutorial")}
          onClick={() => {
            setOpen(false)
            setTimeout(() => {
              setViewTutorial(true)
            }, 200)
          }}
          hidden={open ? "false" : "true"}
        />
        <MenuButtonMobile
          title="Be Iren"
          onClick={() => {
            setOpen(false)
            window.location.href =
              window.location.href.split("?")[0] + "?url=beiren"
          }}
          hidden={open ? "false" : "true"}
        />
        <MenuButtonMobile
          title={t("services")}
          withBorder={false}
          onClick={() => {
            setOpen(false)
            if (conditions.advertising) {
              setTimeout(() => {
                navigate("/services")
              }, 200)
            } else {
              window.location.href =
                window.location.href.split("?")[0] + "?esito=1"
            }
          }}
          hidden={open ? "false" : "true"}
        />
      </Stack>
    </motion.div>
  )
}

export default MenuMobile
