import { ButtonBase, LinearProgress } from "@mui/material"
import { CSSProperties, ReactNode, useContext } from "react"
import { colors } from "../../../services/config/colors"
import { MainContext } from "../../../controllers/main"

const Button = ({
  children,
  style,
  width = "calc(100% - 32px)",
  onClick,
  onFocusVisible,
  onBlur,
  disabled = false,
  loading = false,
  outlined = false,
  startIcon,
  title,
  uppercase = true,
  fontSize,
}: {
  children?: ReactNode
  style?: CSSProperties
  width?: string | number
  onClick?: () => void
  onFocusVisible?: () => void
  onBlur?: () => void
  disabled?: boolean
  loading?: boolean
  outlined?: boolean
  startIcon?: ReactNode
  title: string
  uppercase?: boolean
  fontSize?: number
}) => {
  const { isMobile } = useContext(MainContext)

  return (
    <div
      style={{
        width: width,
        height: 56,
        backgroundColor: colors.backgroundWhite,
        borderRadius: 40,
        position: "relative",
        overflow: "hidden",
        ...style,
      }}
    >
      <ButtonBase
        disabled={disabled || loading}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 40,
          background: outlined
            ? "linear-gradient(90deg, #F7CA46 0%, #E07A2F 100%)"
            : "linear-gradient(90deg, #21599A 0%, #429247 100%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: colors.textWhite,
          fontSize: fontSize ? fontSize : isMobile ? 18 : 20,
          lineHeight: "20px",
          fontWeight: 700,
          transition: "200ms",
          overflow: "hidden",
          textTransform: uppercase ? "uppercase" : "none",
          opacity: disabled || loading ? 0.5 : 1,
        }}
        onClick={onClick}
        onFocusVisible={onFocusVisible}
        onBlur={onBlur}
        aria-label={title}
        aria-disabled={disabled ? "true" : "false"}
      >
        {startIcon ? (
          <div
            style={{
              marginRight: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {startIcon}
          </div>
        ) : null}
        {children}
      </ButtonBase>
      {loading && (
        <LinearProgress
          style={{
            width: "100%",
            position: "absolute",
            bottom: 0,
          }}
        />
      )}
    </div>
  )
}

export default Button
