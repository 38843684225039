import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { useNavigate } from "react-router-dom"
import { t } from "i18next"
import Title from "../global/common/Title"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import AppearFromSide from "../animations/AppearFromSide"
import ServiceCardBig from "./common/ServiceCardBig"

const ServicesMobile = ({
  servicesList,
}: {
  servicesList: {
    title: string
    description: string
    image: string
    onClick: () => void
  }[]
}) => {
  const navigate = useNavigate()

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        paddingBottom: 22,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("services")}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      {/* services */}
      <Stack gap={2} style={{ paddingInline: 16, marginTop: 22 }}>
        {servicesList.map((service, index) => (
          <AppearFromSide
            fade
            key={service.title}
            delay={index * 0.05}
            style={{ width: "100%" }}
          >
            <ServiceCardBig service={service} />
          </AppearFromSide>
        ))}
      </Stack>
    </Stack>
  )
}

export default ServicesMobile
