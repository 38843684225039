import { Stack } from "@mui/material"
import { defaultBoxShadow } from "../../../services/config/constants"
import irenOpenServiceBackgroundImage from "../../../assets/images/iren-open-service-background.jpg"
import servicesBackgroundImage from "../../../assets/images/services-background.jpg"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import Button from "../../global/common/Button"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"

const ServiceCard = ({
  title,
  description,
  buttonLabel,
  buttonOnClick,
  irenOpen,
}: {
  title: string
  description: string
  buttonLabel: string
  buttonOnClick: () => void
  irenOpen?: boolean
}) => {
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      style={{
        width: isMobile ? "100%" : "50%",
        height: isMobile ? 158 : "100%",
        borderRadius: 10,
        boxShadow: defaultBoxShadow,
        backgroundImage: `url(${
          irenOpen ? irenOpenServiceBackgroundImage : servicesBackgroundImage
        })`,
        backgroundSize: "cover",
        backgroundPosition: "90%",
        paddingTop: isMobile ? 16 : 22,
        paddingInline: isMobile ? 16 : 24,
        gap: isMobile ? 12 : 24,
      }}
    >
      <Title
        fontSize={isMobile ? 20 : 26}
        lineHeight={isMobile ? "24px" : "30px"}
        color={colors.textWhite}
      >
        {title}
      </Title>
      <Text
        fontSize={isMobile ? 14 : 20}
        lineHeight={isMobile ? "18px" : "24px"}
        fontWeight={500}
        color={colors.textWhite}
        style={{
          width: irenOpen
            ? isMobile
              ? "90%"
              : "75%"
            : isMobile
            ? "55%"
            : "60%",
          height: isMobile ? 36 : 48,
        }}
      >
        {description}
      </Text>
      <Button
        title={buttonLabel}
        uppercase={false}
        style={{ width: "fit-content", height: isMobile ? 42 : 56 }}
        onClick={buttonOnClick}
        fontSize={isMobile ? 16 : 20}
      >
        <span style={{ paddingInline: 20 }}>{buttonLabel}</span>
      </Button>
    </Stack>
  )
}

export default ServiceCard
