import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import footprintDoughnutImage from "../../assets/images/footprint-doughnut.svg"
import chevronRightPrimaryIcon from "../../assets/icons/chevron-right-primary.svg"
import pointsStarIcon from "../../assets/icons/points-star.svg"
import rocketIcon from "../../assets/icons/rocket.svg"
import infoIcon from "../../assets/icons/info-primary.svg"
import FadeFromTop from "../animations/FadeFromTop"
import { useContext, useState } from "react"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  italianFootprintAverage,
  navBarHeight,
} from "../../services/config/constants"
import AppearFromSide from "../animations/AppearFromSide"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import PointsHistoryCardDesktop from "./desktop/PointsHistoryCardDesktop"
import { useNavigate } from "react-router-dom"
import { scrollWindowToTop } from "../../services/utils/utils"
import { MissionsContext } from "../../controllers/missions"
import { FootprintContext } from "../../controllers/footprint"
import settingsIcon from "../../assets/icons/settings.svg"
import { MainContext } from "../../controllers/main"
import Appear from "../animations/Appear"
import AvatarInfoButton from "../global/desktop/AvatarInfoButton"
import { MissionSubType } from "../../services/config/enum"
import ActionDetailsBackgroundDesktop from "../../assets/svgComponents/ActionDetailsBackgroundDesktop"
import Button from "../global/common/Button"
import Alert from "../global/common/Alert"

const PerformanceDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    user,
    setViewTutorial,
    setCurrentMission,
    windowHeight,
    conditions,
    isApp,
    urlBack,
  } = useContext(MainContext)
  const { missions, completedMissionsCount } = useContext(MissionsContext)
  const { userFootprint, formsList } = useContext(FootprintContext)

  // be iren info alert
  const [beIrenAlertOpen, setBeIrenAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1400 ? `calc(100% - ${footerHeight}px)` : 1400,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[{ label: t("performance") }]}
        selectedItem={0}
        style={{ marginTop: 22 }}
      />
      {/* heading for screen readers only */}
      {/* recap card */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* header */}
          <Stack
            style={{
              position: "relative",
              marginTop: 22,
            }}
          >
            <AppearFromSide fade>
              <Title fontSize={36} lineHeight="46px" component="h1">
                {t("performance")}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={28}
                lineHeight="46px"
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {t("monitor_your_progress")}
              </Text>
            </AppearFromSide>
            <Appear style={{ position: "absolute", right: 0 }} fade>
              <AvatarInfoButton
                title={t("view_tutorial")}
                onClick={() => {
                  scrollWindowToTop()
                  navigate("/")
                  setViewTutorial(true)
                }}
              />
            </Appear>
          </Stack>
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: colors.background,
              marginTop: 40,
              borderRadius: 16,
              padding: 60,
              gap: 13,
            }}
          >
            {/* first column */}
            <Stack
              style={{
                width: "30%",
                minWidth: "30%",
                maxWidth: "30%",
                height: "auto",
                position: "relative",
              }}
            >
              <FadeFromTop>
                <div
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: "100%",
                    backgroundImage: `url(${
                      user!.profileImage
                    }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                    backgroundSize: "105%, 100%",
                    backgroundPosition: "center",
                    position: "relative",
                  }}
                />
              </FadeFromTop>
              <FadeFromTop
                delay={0.05}
                style={{ position: "absolute", bottom: 0 }}
              >
                <ButtonBase
                  disableRipple
                  aria-label={t("settings")}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/performance/settings")
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ height: 28, gap: 11 }}
                  >
                    <img
                      src={settingsIcon}
                      style={{ width: 24, height: 24 }}
                      alt=""
                    />
                    <Title
                      fontSize={20}
                      lineHeight="28px"
                      color={colors.primary}
                      style={{ textDecoration: "underline" }}
                    >
                      {t("settings")}
                    </Title>
                  </Stack>
                </ButtonBase>
              </FadeFromTop>
            </Stack>
            {/* second column */}
            <Stack
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "auto",
                gap: 30,
              }}
            >
              <FadeFromTop style={{ width: "100%" }}>
                <ButtonBase
                  disabled={
                    !userFootprint &&
                    !missions.some(
                      (mission) =>
                        mission.missionSubType === MissionSubType.TYPEFORM_BASE
                    )
                  }
                  style={{
                    width: "100%",
                    borderRadius: 16,
                    border: "1px solid " + colors.primary,
                  }}
                  onClick={() => {
                    scrollWindowToTop()
                    if (
                      formsList.length &&
                      formsList.some((item) => !item.isRefine) &&
                      !userFootprint
                    ) {
                      const missionToSet = missions.find(
                        (mission) =>
                          mission.missionSubType ===
                          MissionSubType.TYPEFORM_BASE
                      )!
                      setCurrentMission(missionToSet)
                      localStorage.setItem(
                        "currentMission",
                        JSON.stringify(missionToSet)
                      )
                      navigate("/performance/footprint/form")
                    } else {
                      navigate("/performance/footprint")
                    }
                  }}
                  aria-label={t("your_carbon_footprint")}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: 92,
                      borderRadius: 16,
                      backgroundColor: colors.backgroundWhite,
                      paddingLeft: 22,
                      gap: 22,
                      position: "relative",
                    }}
                  >
                    <img
                      src={footprintDoughnutImage}
                      style={{ height: 58 }}
                      alt=""
                    />
                    <Stack style={{ gap: 10 }}>
                      <Title fontSize={20} lineHeight="24px">
                        {t("your_carbon_footprint")}
                      </Title>
                      {!userFootprint &&
                      !missions.some(
                        (mission) =>
                          mission.missionSubType ===
                          MissionSubType.TYPEFORM_BASE
                      ) ? (
                        <div style={{ height: 19 }} />
                      ) : (
                        <Text fontSize={16} fontWeight={400}>
                          {userFootprint
                            ? `${Math.abs(
                                Math.round(
                                  ((userFootprint.footprint -
                                    italianFootprintAverage) /
                                    ((userFootprint.footprint +
                                      italianFootprintAverage) /
                                      2)) *
                                    100
                                )
                              )}% ${
                                userFootprint.footprint >
                                italianFootprintAverage
                                  ? t("above_average")
                                  : t("below_average")
                              }`
                            : t("answer_the_survey")}
                        </Text>
                      )}
                    </Stack>
                    <Stack
                      justifyContent="flex-end"
                      style={{
                        position: "absolute",
                        right:
                          !userFootprint &&
                          !missions.some(
                            (mission) =>
                              mission.missionSubType ===
                              MissionSubType.TYPEFORM_BASE
                          )
                            ? 28
                            : 85,
                      }}
                    >
                      <Title
                        fontSize={26}
                        lineHeight="30px"
                        color={colors.primary}
                        textAlign="right"
                      >
                        {userFootprint
                          ? userFootprint.footprint.toString().replace(".", ",")
                          : "--"}
                      </Title>
                      <Text fontSize={16} lineHeight="30px" fontWeight={400}>
                        {t("kg_co2e")}
                      </Text>
                    </Stack>
                    {!userFootprint &&
                    !missions.some(
                      (mission) =>
                        mission.missionSubType === MissionSubType.TYPEFORM_BASE
                    ) ? null : (
                      <img
                        src={chevronRightPrimaryIcon}
                        style={{ height: 20, position: "absolute", right: 28 }}
                        alt=""
                      />
                    )}
                  </Stack>
                </ButtonBase>
              </FadeFromTop>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  height: 178,
                  gap: desktopColumnsGap,
                }}
              >
                <FadeFromTop
                  style={{ width: "100%", height: "100%" }}
                  delay={0.05}
                >
                  <Stack
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 16,
                      border: "1px solid " + colors.primary,
                      backgroundColor: colors.backgroundWhite,
                      position: "relative",
                    }}
                  >
                    <img
                      src={pointsStarIcon}
                      style={{ height: 30, marginTop: 21 }}
                      alt=""
                    />
                    <Title
                      fontSize={26}
                      lineHeight="28px"
                      color={colors.primary}
                      style={{ marginTop: 16 }}
                    >
                      {user?.points}
                    </Title>
                    <Text
                      fontSize={16}
                      lineHeight="18px"
                      fontWeight={400}
                      style={{ marginTop: 5 }}
                    >
                      {t("points_extended")}
                    </Text>
                    {conditions.beiren ? (
                      <Text
                        fontSize={14}
                        lineHeight="16px"
                        style={{ marginTop: 16, fontStyle: "italic" }}
                      >
                        Equivalenti a {user?.points}pt Be Iren
                      </Text>
                    ) : (
                      <Button
                        title="Registrati a Be Iren"
                        width={185}
                        fontSize={13}
                        style={{ height: 40, marginTop: 8 }}
                        onClick={() => {
                          if (isApp) {
                            window.location.href =
                              window.location.href.split("?")[0] + "?url=beiren"
                          } else {
                            window.location.href =
                              urlBack + "/carbon-footprint-redirect?url=beiren"
                          }
                        }}
                      >
                        Registrati a Be Iren
                      </Button>
                    )}
                    <ButtonBase
                      aria-label="Perchè devo registrarmi a Be Iren?"
                      style={{
                        borderRadius: "100%",
                        position: "absolute",
                        top: 12,
                        right: 16,
                      }}
                      onClick={() => {
                        setBeIrenAlertOpen(true)
                      }}
                    >
                      <img src={infoIcon} style={{ width: 20, height: 20 }} />
                    </ButtonBase>
                  </Stack>
                </FadeFromTop>
                <FadeFromTop
                  style={{ width: "100%", height: "100%" }}
                  delay={0.05}
                >
                  <Stack
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 16,
                      border: "1px solid " + colors.primary,
                      backgroundColor: colors.backgroundWhite,
                    }}
                  >
                    <img
                      src={rocketIcon}
                      style={{ height: 30, marginTop: 21 }}
                      alt=""
                    />
                    <Title
                      fontSize={26}
                      lineHeight="28px"
                      color={colors.primary}
                      style={{ marginTop: 16 }}
                    >
                      {completedMissionsCount}
                    </Title>
                    <Text
                      fontSize={16}
                      lineHeight="18px"
                      fontWeight={400}
                      textAlign="center"
                      style={{ marginTop: 5, width: 100 }}
                    >
                      {completedMissionsCount === 1
                        ? t("completed_mission")
                        : t("completed_missions")}
                    </Text>
                  </Stack>
                </FadeFromTop>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </PaddingContainerDesktop>
      {/* performance and leaderboard section */}
      <PaddingContainerDesktop>
        <Stack
          direction="row"
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            height: "auto",
            marginTop: 60,
            marginBottom: 62,
          }}
        >
          {/* performance */}
          <AppearFromSide
            delay={0.1}
            fade
            style={{
              width: "48%",
              minWidth: "48%",
            }}
          >
            <Stack
              style={{
                width: "100%",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", position: "relative" }}
              >
                <Title fontSize={26} lineHeight="46px" component="h2">
                  {t("missions")}
                </Title>
              </Stack>
              <PointsHistoryCardDesktop />
              {completedMissionsCount > 5 ? (
                <ButtonBase
                  style={{
                    width: 82,
                    height: 24.5,
                    position: "relative",
                    marginTop: 25,
                    alignSelf: "flex-end",
                  }}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/performance/missions")
                  }}
                  aria-label={t("see_all")}
                >
                  <Text
                    fontSize={18}
                    fontWeight={700}
                    color={colors.primary}
                    style={{
                      textDecorationLine: "underline",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  >
                    {t("see_all")}
                  </Text>
                </ButtonBase>
              ) : null}
            </Stack>
          </AppearFromSide>
        </Stack>
      </PaddingContainerDesktop>
      {/* background */}
      <div
        style={{
          width: "100%",
          maxWidth: 1920,
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "absolute",
          top: 0,
        }}
      >
        <ActionDetailsBackgroundDesktop
          color={colors.background}
          style={{ position: "absolute", top: 960, right: -160 }}
        />
      </div>
      {/* alerts */}
      <Alert
        open={beIrenAlertOpen}
        title="Perchè devo registrarmi a Be Iren?"
        description="Be Iren è il <strong>programma fedeltà</strong> dedicato a tutti i clienti domestici con un contratto fornitura di luce e/o gas attivo nel mercato libero.<br /><br /><strong>Be Iren ti permette di vincere ogni mese fantastici premi.</strong> Se sei iscritto al programma fedeltà Be Iren e completi le Missioni di AWorld, potrai accumulare fino a <strong>14 punti extra</strong> al mese da spendere all’interno del Catalogo e di accedere all’estrazione di ulteriori premi.<br /><br />Se non ti registri non potrai accedere al catalogo dei premi."
        isDescriptionHtml
        primaryActionLabel="Registrati"
        primaryActionOnClick={() => {
          if (isApp) {
            window.location.href =
              window.location.href.split("?")[0] + "?url=beiren"
          } else {
            window.location.href =
              urlBack + "/carbon-footprint-redirect?url=beiren"
          }
        }}
        secondaryActionLabel="Indietro"
        secondaryActionOnClick={() => {
          setBeIrenAlertOpen(false)
        }}
        isSecondaryActionPrimary={false}
      />
    </Stack>
  )
}

export default PerformanceDesktop
